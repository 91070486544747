import "./AddProjectPopup.css";

import { requiredValidator, validateGuid } from "../../../../lib/utils/ValidationChecks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../lib/components/buttons/Button";
import { CreatingProjectDTO } from "../../../../common/models/CreatingProjectDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { ProjectService } from "../../services/ProjectService";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

interface IProps {
  onCompleted: () => void;
}



var prjService = new ProjectService();

export function AddProjectPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();

  const projectNameFormControl = useFormControl<string>({ validators: [requiredValidator()], enableAutoValidate: true, initialValue: '' });
  const guidIdFormControl = useFormControl<string>({ validators: [validateGuid()], enableAutoValidate: true, initialValue: '' });

  const createProjectCall = useServiceCallPro(prjService.createProject);


  /****************************
   * DATA REQUESTS
   *****************************/

  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  const isFormValid = useMemo(() => projectNameFormControl.isValid && guidIdFormControl.isValid && 
  projectNameFormControl.value && guidIdFormControl.value , [projectNameFormControl, guidIdFormControl]);

  const isButtonDisabled = useMemo(() => {
    return !isFormValid || createProjectCall.isLoading;
  }, [isFormValid, createProjectCall.isLoading]);


  /****************************
  * USER ACTIONS
  *****************************/

  const handleAddProjectClicked = useCallback(() => {

    if (!isFormValid || createProjectCall.isLoading) return;

    var requestBody: CreatingProjectDTO = {
      name: projectNameFormControl.value,
      externalId: guidIdFormControl.value
    };

    createProjectCall.invoke(requestBody).then((response) => {

      openPopup(<SuccessPopup>
        <div>New Project with GUID: {response.externalId}</div>
      </SuccessPopup>);

      props.onCompleted();
    }).catch((err) => {
      console.log(err);
      openPopup(<ErrorPopup><div>{err.response.data.message}</div></ErrorPopup>);
    });

  },
    [isFormValid, projectNameFormControl, guidIdFormControl, openPopup, createProjectCall]
  );

  /****************************
  * CSS & HTML
  *****************************/

  if (createProjectCall.isLoading) {
    return (
      <PopupContainer className="add-project-popup">
        <Loader />
      </PopupContainer>
    )
  }

  return (
    <PopupContainer className="add-project-popup">

      <h3>Add project</h3>
      <Spacer mode="vertical" px="16" />
  
      <FormContainer className="inputs">
        <FormFieldTextInput label="Project Name" formControl={projectNameFormControl} dataTest="projectNameInput" />
        <FormFieldTextInput label="External ID" formControl={guidIdFormControl} dataTest="externalIdInput" />
      </FormContainer>

      <Spacer mode="vertical" px="16" />
      <LineSeparator />

      <FlexLayout className="act-btns" direction='horizontal' horizontalAlign='end' verticalAlign="center"  >

        <TextButton text="Cancel" type="white" onClick={closePopup} />
        <Button text="Add" type="primary" isDisabled={isButtonDisabled}
          onClick={handleAddProjectClicked} />

      </FlexLayout>

    </PopupContainer>
  );
}


