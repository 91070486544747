import { DateTime } from "luxon";
import { LoggerDTO } from "../../../common/models/LoggerDTO";
import { LogLevel } from "../../../common/models/LogLevel";
import { HttpClient, getSessionId } from "../http/HttpClient";
import {v4 as uuidv4} from 'uuid';

export class Logger {
    
    // SINGLETON
    static instance: Logger|null = null;
    static getInstance() : Logger {
        if(Logger.instance == null)
            Logger.instance = new Logger();
        return Logger.instance;
    }

    projectId: string;
    componentId: string;
    actor: string | null = null;
    operationName: string | undefined;
    

    constructor(){
        this.projectId = "4bf89363-3679-47a7-b4c9-d74522f8e228";
        this.componentId = "833d7bfe-2966-4f41-a623-517e1bb7d86b";
    }

    
    debug(message: string, description: string | null, operationName: string | null, correlationId: string | null) {
        this.log(LogLevel.DEBUG, message, description, operationName, correlationId)
    }
    info(message: string, description: string | null, operationName: string | null, correlationId: string | null) {
        this.log(LogLevel.INFO, message, description, operationName, correlationId)
    }
    trace(message: string, description: string | null, operationName: string | null, correlationId: string | null) {
        this.log(LogLevel.TRACE, message, description, operationName, correlationId)
    }
    error(message: string, description: string | null, operationName: string | null, correlationId: string | null) {
        this.log(LogLevel.ERROR, message, description, operationName, correlationId)
    }
    fatal(message: string, description: string | null, operationName: string | null, correlationId: string | null) {
        this.log(LogLevel.FATAL, message, description, operationName, correlationId)
    }
    criticalError(message: string, description: string | null, operationName: string | null, correlationId: string | null) {
        this.log(LogLevel.CRITICAL_ERROR, message, description, operationName, correlationId)
    }


    log(level: LogLevel, message: string, description: string | null, operationName: string | null, correlationId: string | null): Promise<LoggerDTO> {

        var log: LoggerDTO = {
            timeStamp: DateTime.now().toString(),
            projectId: this.projectId, 
            componentId: this.componentId, 
            correlationId: correlationId ?? uuidv4(), 
            sessionId: getSessionId(),
            level: level,
            message: message,
            actor: this.actor ?? null, 
            description: description,
            operationName: this.formatOperationName(operationName ?? this.operationName),
        };

        return HttpClient.sessionRequest<LoggerDTO>({
            method: "POST",
            url: "https://aurora.apis.orbitalwatch.dev/api/Logs",
            data: log,
        })
        .then((res) => res.data)
        .catch((error) => {
            throw error;
        });
    }

    // To avoid spelling mistakes, all operation name will have the same formatting (Upper Case and "_" spaces)
    formatOperationName(operationName: string | null | undefined): string | null {
        return operationName?.toUpperCase().replace(" ", "_") ?? null;
    }
}
