import "./LogsRealTimePage.css";

import { RealtimeLogsEvent, RealtimeProvider } from "../../services/RealtimeProvider";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ComponentSelectOption } from "../../../../common/models/common/ComponentSelectOption";
import { ComponentService } from "../../../components/services/ComponentService";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { LogDTO } from "../../../../common/models/LogDTO";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { RowDefinition } from "../../../../lib/components/table/Table";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { mapComponentDtoToComponentSelectOptions } from "../../mappers";
import { useEvent } from "../../../../common/events/useEvent";
import { useFormControl } from "../../../../lib/components/form/Form";
import { useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

var compontnSvc = new ComponentService();

/****************************
 * MAPPERS
 *****************************/
const componentLabelSelector = (item: ComponentSelectOption) => item.name;
const componentIdSelector = (item: ComponentSelectOption) => item.key;

export function LogsRealTimePage() {

    const navigate = useNavigate();
    const { projectId } = useParams();


    /** Table states **/
    const [componentLogs, setComponentLogs] = useState<LogDTO[]>();
    const [components, setComponents] = useState<ComponentSelectOption[]>([]);
    const getComponentsCall = useServiceCallPro(compontnSvc.getComponents)

    const componentFilter = useFormControl<ComponentSelectOption>({});

    /****************************
     * DATA REQUESTS
     *****************************/
 
    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(()=> {
        const request = {
            page: 0,
            pageLength: 50,
            column: 'Name',
            isDescending: false,
          }
      
          if (!projectId) return;
      
          getComponentsCall.invoke(request, projectId).then((res) => {
            setComponents(mapComponentDtoToComponentSelectOptions(res.records));
          });

    },[])


    useEffect(()=> {
        components[0] && componentFilter.setValue(components[0])
    },[components])

    useEffect(()=> {
        if (componentFilter.value?.key) {
            RealtimeProvider.subscribeGroup(componentFilter.value.key)
            return () => { RealtimeProvider.unSubscribeGroup(componentFilter.value?.key ?? "") }
        }
        return ()=>{}
    },[componentFilter.value?.key])

    useEvent("realtime-logsReceived", (event: RealtimeLogsEvent) => {
        const c = componentLogs ? [...componentLogs] : []
        setComponentLogs([...event.logs,...c])
    } , [componentLogs]);


    /****************************
     * USER ACTIONS
     *****************************/
 
    const handleLogClicked = useCallback((log: LogDTO, eventButtonId?: number) => {
        switch (eventButtonId) {
            case 0: //right click
                navigate(log.id || "");
                break;
            case 1: //middle mouse
                window.open(`${window.location.origin}${window.location.pathname}${log.id || ""}`, '_blank');
                break;
        }
    }, [navigate]);



    /****************************
     * CSS & HTML
     *****************************/

    const rowDefinition = useMemo((): RowDefinition<LogDTO> => ({
        onClick: handleLogClicked
    }), [handleLogClicked]);

    return (
        <PageContainer className="logs-realtime-page">

            <Spacer mode="vertical" px="28.5" />
            <h1>Real Time Logs</h1>
            <Spacer mode="vertical" px="23" />

            <FormFieldSelectSingle
                    label={"Component"}
                    options={components}
                    labelSelector={componentLabelSelector}
                    idSelector={componentIdSelector}
                    placeholder={"Select a component"}
                    formControl={componentFilter}
                    maxHeightOptions={"10rem"}
                />

            <Spacer mode="vertical" px="12" />

            <div className="dark-screen">
                {componentLogs?.map(log => (<div className="log">[{log.timeStamp}] [{log.correlationId}] {log.operationName} | {log.message} | {log.description} </div>))}
            </div>

        </PageContainer>
    );
}
