import "./FormField.css";

import { CssClassNameBuilder } from '../../../utils/CssClassNameBuilder';
import { IFormControl } from "../Form";
import { useMemo } from "react";

interface IFormFieldProps<T> {
    label?: string;
    children: React.ReactNode;
    formControl: IFormControl<T>;
}

// TODO: reservar espaço da label.
// TODO: reservar 1 linha de erro.

export function FormField<T>(props: IFormFieldProps<T>) {


    const formFieldCss = useMemo(() => {

        return CssClassNameBuilder.new()
            .add("form-field")
            .addConditional(props.formControl.isDisabled, "disabled")
            .addConditional(props.formControl.hasErrors, "error")
            .build();
    },[props.formControl.isDisabled, props.formControl.hasErrors]);


    return (
      <div className={formFieldCss}>
            {props.label ? <div className="form-field-label">{props.label}</div> : null}
            {props.children}
            <div>
                {props.formControl.hasErrors ?
                    props.formControl.errorMessages?.map((i, idx) => (<div key={idx} className="caption form-field-error">{i}</div>))
                : null}
            </div>
      </div>
    )
  }
