import { ILanguage } from "./ILanguage";

export const languageEN: ILanguage = {
  COMMON: {
    ProjectName: "AURORA",
    Continue: "Continue",
    Yes: "Yes",
    No: "No",
    Cancel: "Cancel",
    Create: "Create",
    Add: "Add",
    Activate: "Activate",
    Import: "Import",
    Change: "Change",
    Suspend: "Suspend",
    Save: "Save",
    Edit: "Edit",
    Delete: "Delete",
    Remove: "Remove",
    Deactivate: "Deactivate",
    Logout: "Logout",
    Optional: "Optional",
    Registar: "Create",
    PrepositionForDateTime: " on ",
    CreatedBy: "Registed by",
    CreatedOn: "Registed on",
    All: "All",
    Clear: "Clear",
    Deliver: "Deliver",
    OtherActions: "Other actions",
    CloneAction: "Clone Sample",
    CloneRequestAction: "Clone Request",
    SelectOneOptionPlaceholder: "Select one option",
    Name: "Name",
    Back: "Back",
    


    FORM: {
      Details: "Details",
      Search: "Search",
      SearchPlaceholder: "Search",
      InitialDate: "Initial Date",
      InitialDatePlaceholder: "Initial Date",
      InitialCreationDatePlaceholder: "Initial Creation Date",
      FinalDate: "Final Date",
      FinalDatePlaceholder: "Final Date",
      FinalCreationDatePlaceholder: "Final Creation Date",
      RequestDatePlaceholder: "Select a date",
      DefaultPlaceholder: "Type Here",
      SelectDefaultPlaceholder: "Type to search",
      SelectStatusPlaceholder: "Select status",
      Filters: "Filters",
      CleanFilters: "Reset Filters",
      ClearSelection: "Clear selection",
      Sunday: "Su",
      Monday: "Mo",
      Tuesday: "Tu",
      Wednesday: "We",
      Thursday: "Th",
      Friday: "Fr",
      Saturday: "Sa",
      MoreOptionsBtn: "View more...",

      VALIDATIONS: {
        CannotBeEmpty: "This field cannot be empty.",
        RequiredField: "This field is required.",
        GuidValidator: "Invalid GUID format.",
        SelectOneOption: "Select an option",
        BatchFormatError: "Must contain the following format: ####### or #######/# or #######/##",
        FillMandatoryFields: "Please fill the mandatory fields",
        InvalidLocationId: "Location ID is invalid.",
        InvalidRequestId: "Analysis Request ID is invalid.",
        PositiveNumber: "Insert an integer number",
        PositiveNumberOrDecimal: "Insert an integer number / decimal",
        SelectDate: "Please select a date"
      }
    },
    POPUPS: {
      ImportOptions: "Import Options",
      ExcelTemplate: "Excel Template",
      ExcelTemplateText: "Click the button below to download an Excel template file. This template contains the correct structure for data import.",
      Import: "Import",
      ImportText: "Click the button below to select an Excel file and start the import process. Make sure you select the correct file.",
    },

    ERRORPAGES: {
      SomethingWrong: "Something Went Wrong",
      BackToHome: "Back to Home",
      AccessDenied: "Access Denied",
      AccessDeniedText1: "You do not have permission to view this page!",
      AccessDeniedText2: "Please check your credentials and try again.",
      PageNotFound: "Page Not Found",
      PageNotFoundText: "We can't seem to find the page you are looking for.",
      SessionExpired: "Session Expired",
      SessionExpiredText: "Your session has expired due to your inactivity.",
      Login: "Login",
    },

    SYSTEMPOPUPS: {
      Error: "Error!",
      ErrorText: "",
      Success: "Success!",
      SuccessText: "",
      Warning: "Warning",
      WarningText: "",
    },

    TABLE: {
      NoResults: "No results found",
      NoResultsText: "We couldn't find what you're looking for, please search again",
      RowsPerPage: "Rows per page",
      Of: "of",
    }
  },
};
