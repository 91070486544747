import "./Button.css";

import { useCallback, useMemo } from 'react';

import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder";

export interface IButtonProps {
    text: string;
    type: 'primary' | 'secondary' | 'white';
    icon?: React.ReactNode;
    isDisabled?: boolean;
    className?: string;
    onClick?: (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}


export function Button(props: IButtonProps){


    const buttonCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("button")
            .addConditional(props.type, props.type)
            .addConditional(props.isDisabled, "disabled")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className, props.type, props.isDisabled]);


    const handleClicked = useCallback((ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        ev.stopPropagation();
        if(props.isDisabled) return;
        props.onClick && props.onClick(ev);
    }, [props.onClick, props.isDisabled]);

    return (

        <div className={buttonCss} onClick={handleClicked}>
            {props.icon ? <div className="button-icon">{props.icon}</div> : null}
            {props.text}
        </div>
    );
}
