import "./DatePicker.css";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar-icon.svg";
import { CssClassNameBuilder } from "../../../utils/CssClassNameBuilder";
import { DatePickerCalendar } from "./DatePickerCalendar";
import { DateTime } from "luxon";

export interface IDatePickerProps {
    value?: DateTime;
    selectedDate?: DateTime;
    onChange: (newDate: DateTime | undefined) => void;
    placeholder?: string;
    className?: string;
    isRequired?: boolean;
    isDisabled?: boolean;
    hasError?: boolean;
    minDate?: DateTime | undefined;
    maxDate?: DateTime | undefined;

}

export function DatePicker(props: IDatePickerProps) {
    const datePickerRef = useRef<HTMLInputElement>(null);

    const [showSelectPanel, setShowSelectPanel] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<DateTime | undefined>(props.value);
    const [panelPosStyle, setPanelPosStyle] = useState<{}>({});

    useEffect(() => {
        if (datePickerRef.current && showSelectPanel) {
            const panelPosTop =
                datePickerRef.current.getBoundingClientRect().top +
                datePickerRef.current.clientHeight +
                2;
            const panelPosBottom =
                window.innerHeight -
                datePickerRef.current.getBoundingClientRect().bottom +
                datePickerRef.current.clientHeight +
                2;
            const panelPosLeft = datePickerRef.current.getBoundingClientRect().left;
            const remainingHeight = window.innerHeight - panelPosTop;
            if (datePickerRef.current.getBoundingClientRect().top > remainingHeight) {
                setPanelPosStyle({
                    margin: "auto 0",
                    bottom: panelPosBottom,
                    left: panelPosLeft + "px",
                    opacity: 1,
                });
            } else {
                setPanelPosStyle({
                    top: panelPosTop + "px",
                    left: panelPosLeft + "px",
                    opacity: 1,
                });
            }
        }
    }, [showSelectPanel, setShowSelectPanel]);

    useEffect(() => {
        const handleScroll = (e: any) => {
            setShowSelectPanel(false);
        };
        window.addEventListener("scroll", handleScroll, true);
        return () => window.removeEventListener("click", handleScroll, true);
    }, [showSelectPanel, setShowSelectPanel]);

    const handleOnClickOutside = useCallback(() => {
        setShowSelectPanel(false);
    }, []);

    const handleOnChange = useCallback(
        (value: DateTime | undefined) => {
            props.onChange(value);
            setSelectedDate(value);
            setShowSelectPanel(false);
        },
        [props.onChange, setSelectedDate, setShowSelectPanel]
    );

    const handleClickDatePicker = useCallback(() => {


        setShowSelectPanel(!showSelectPanel);
    }, [setShowSelectPanel, showSelectPanel]);



    const renderDatePickerCalendar = useMemo(() => {

        if (showSelectPanel && !props.isDisabled)
            return (
                <DatePickerCalendar
                    onClickOutside={() => handleOnClickOutside()}
                    calendarPosition={panelPosStyle}
                    selectedDate={selectedDate ? selectedDate : undefined}
                    onChange={(value: DateTime | undefined) => handleOnChange(value)}
                    minDate={props.minDate}
                    maxDate={props.maxDate}

                />
            );
    }, [showSelectPanel, handleOnClickOutside, panelPosStyle, selectedDate, handleOnChange]);



    const datePickerCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("date-picker")
            .addConditional(props.className, props.className)
            .addConditional(props.hasError, "error")
            .addConditional(props.isDisabled, "disabled")
            .build();
    }, [props.className, props.hasError, props.isDisabled]);

    return (
        <div className={datePickerCss}>
            <div
                ref={datePickerRef}
                className="date-picker-input"
                onClick={handleClickDatePicker}
            >
                <input
                    type="text"
                    readOnly
                    placeholder={props.placeholder}
                    value={selectedDate ? selectedDate.toFormat("dd/MM/yyyy") : ""}
                    required={props.isRequired}
                    disabled={props.isDisabled}
                />
                <CalendarIcon />
            </div>
            {renderDatePickerCalendar}
        </div>
    );
}
