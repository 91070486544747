import { ComponentSelectOption } from "../../../../common/models/common/ComponentSelectOption";
import { DateTime } from "luxon";
import { LogLevelSelectOption } from "../../../../common/models/common/LogLevelSelectOption";
import { logLevelOptions } from "./LogsHomePage";

export function mapToLogLevelSelectOption(params : string[] | null): LogLevelSelectOption[] | undefined {
    if(params == null) { return undefined; }
    return params.map(param => {
        var int = parseInt(param);
        return logLevelOptions.find(option => option.key === int);
    }).filter(option => option !== undefined) as LogLevelSelectOption[];
}

export function mapToComponentSelectOption(param : string | null): ComponentSelectOption | undefined {
    if(param == null) { return undefined; }
    return {key: param, name: ""};
}

export function mapToDateTime(param : string | null): DateTime | undefined {
    if(param == null) { return undefined; }
    return DateTime.fromISO(param);
}
