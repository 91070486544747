import "./TableRow.css";

import { ITableCellProps as ITableCell, TableCell } from "../table-cell/TableCell";
import { MouseEventHandler, useCallback, useMemo } from "react";

import { CssClassNameBuilder } from "../../../utils/CssClassNameBuilder";

export interface ITableRowProps {
        className?: string;
        cells: ITableCell[];
        onClick?: (eventButtonId?: number) => void;
    }

    export function TableRow(props: ITableRowProps) {

        /****************************
        * USER ACTIONS
        *****************************/

        const handleRowClicked = useCallback((event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
            props.onClick && props.onClick(event.button);
        }, [props.onClick]);

        /****************************
         * CSS && HTML
         *****************************/
        const tableRowCss = useMemo(() => {
            return CssClassNameBuilder.new()
                .add("table-row")
                .addConditional(props.className, props.className)
                .addConditional(props.onClick !== undefined, "clickable")
                .build();
        }, [props.className]);

        const cells = useMemo(() => {
            return props.cells.map((cell, idx) => {
                let tableCellCss = CssClassNameBuilder.new()
                    .addConditional(cell.className, cell.className)
                    .add("table-row-cell")
                    .build();
                return <TableCell className={tableCellCss} key={idx}>{cell.children} </TableCell>
            })
        }, [props.cells]);


        return (
            <tr className={tableRowCss} onMouseDown={handleRowClicked}>
                {cells}
            </tr>
        )
    }