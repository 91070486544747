import { Navigate, Route, Routes } from "react-router-dom";

import { NavBar } from "../lib/components/nav-bar/NavBar";
import { ProjectsHomePage } from "../features/projects/pages/projects-home/ProjectsHomePage";
import { ProjectsModule } from "./lv1/ProjectsModule";

export function AppRoutingModule() {

    return (
        <>
            <NavBar />
            <Routes>
                <Route path="/projects" element={<ProjectsHomePage />} />
                <Route path="/projects/:projectId/*" element={<ProjectsModule />} />
                <Route path="*" element={<Navigate to="/notfound"/>}/>
            </Routes>
        </>
    )
}
