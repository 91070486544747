import { IdentityService } from "../IdentityService";
import { SplashScreen } from "../../../../common/pages/splash-screen/SplashScreen";
import { useEffect } from "react";

interface IIdentityActionProps {

    exec: (svc : IdentityService) => void;
}

export function IdentityAction(props: IIdentityActionProps) {

    useEffect(() => {
        props.exec(IdentityService.Global);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <SplashScreen/>
    );

}
