
import { DateTime } from "luxon";

export class DateTimeHelper {
    
  static formatDateTime(dateString: string) {
    let date = DateTime.fromISO(dateString).toLocal();

    return date.toFormat("yyyy/MM/dd HH:mm:ss");
  }

  static formatDateTimeWithoutHours(dateString: string) {
    let date = DateTime.fromISO(dateString).toLocal();

    return date.toFormat("yyyy/MM/dd");
  }
}
