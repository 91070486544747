import { DiamondSVG, WarningMessageSVG } from "../../lib/assets/icons";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

import { ComponentsHomePage } from "../../features/components/pages/components-home/ComponentsHomePage";
import { LogsModule } from "./lv2/LogsModule";
import { LogsRealTimePage } from "../../features/logs/pages/logs-real-time/LogsRealTimePage";
import { MenuItem } from "../../lib/components/side-bar/MenuItem";
import { ProjectService } from "../../features/projects/services/ProjectService";
import { Sidebar } from "../../lib/components/side-bar/Sidebar";
import { useEffect } from "react";
import { useSetSelectedProject } from "../../lib/infrastructure/ui/UIServices";

const sideBarButtons: MenuItem[] = [
    {
        icon: <DiamondSVG />,
        navigateTo: "components/"
    },
    {
        icon: <WarningMessageSVG />,
        navigateTo: "logs?level=4&level=5&level=6"
    },
    {
        icon: <WarningMessageSVG />,
        navigateTo: "realtime/"
    }
];

export function ProjectsModule() {

    const [selectedProject, setSelectedProject] = useSetSelectedProject();
    const location = useLocation();
    const { projectId } = useParams();
    var projectSvc = new ProjectService();

    useEffect(() => {
        if (projectId && projectId != selectedProject?.id) {
            projectSvc.getById(Number(projectId)).then((proj) => {
                setSelectedProject({ id: projectId, name: proj.name });
            });
        }
    }, [projectId, location]);

    return (
        <div className="main-content">
            <div className="sidebar-content">
                <Sidebar buttons={sideBarButtons} />
            </div>

            <div className="content">
                <Routes>
                    <Route index element={<Navigate to="logs?level=4&level=5&level=6" />} />
                    <Route path="logs/*" element={<LogsModule />} />
                    <Route path="components" element={<ComponentsHomePage />} />
                    <Route path="realtime" element={<LogsRealTimePage />} />
                </Routes>
            </div>
        </div>
    );
}
