import "./LogTracePage.css";

import { ColumnDefinition, RowDefinition, Table } from "../../../../lib/components/table/Table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useInitLoader, useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { useNavigate, useParams } from "react-router-dom";

import { CircleArrowSVG } from "../../../../lib/assets/icons";
import { CssClassNameBuilder } from "../../../../lib/utils/CssClassNameBuilder";
import { DateTimeHelper } from "../../../../common/utils/DateTimeHelper";
import { DetailCorrelatedLogPopup } from "./DetailCorrelatedLogPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { JsonStringHelper } from "../../../../common/utils/JsonStringHelper";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { LogDTO } from "../../../../common/models/LogDTO";
import { LogLevel } from "../../../../common/models/LogLevel";
import { LogService } from "../../services/LogService";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";

var logsService = new LogService();

const DetailCellRenderer = (props: { onClick: () => void }) => {
    return (
        <div className="options-cell">
            <CircleArrowSVG onClick={props.onClick} />
        </div>
    );
};

function getLogLevelNameById(key: number) {
    if (LogLevel[key]) {
        return <div>{LogLevel[key]}</div>
    }
    return <div>*null*</div>
}

interface ColDef {
    log: LogDTO,
    currentIndex: number;
    logLevel: string;
}


export function LogTracePage() {

    const navigate = useNavigate();
    const openPopup = usePopup();

    const { projectId: prjId, logId: lId } = useParams();

    const [logDetail, setLogDetail] = useState<LogDTO>();
    const [logsCorrelated, setLogsCorrelated] = useState<ColDef[]>([]);

    const getLogsCorrelatedCall = useServiceCallPro(logsService.getLogsCorrelated);
    const getLogDetailsCall = useServiceCallPro(logsService.getLogDetails);

    const initLoader = useInitLoader();

    /****************************
    * DATA REQUESTS
    *****************************/
    const getLogsCorrelated = useCallback(() => {
        if (prjId && lId) {
            getLogsCorrelatedCall.invoke(prjId, lId).then((res) => {
                const correlatedLogsMapped = res.map((log, idx): ColDef => ({ // ignore Error it works
                    currentIndex: idx,
                    log: log,
                    logLevel: LogLevel[log.level],
                }));
                setLogsCorrelated(correlatedLogsMapped);
            }).catch((error) => {
                openPopup(<ErrorPopup><div>{error.message}</div></ErrorPopup>);
            })
        }
    }, [setLogsCorrelated, openPopup, prjId, lId]);


    const getLogDetails = useCallback(() => {

        if (prjId && lId) {
            getLogDetailsCall.invoke(prjId, lId).then((res) => {
                setLogDetail(res);
            }).catch((error) => {
                openPopup(<ErrorPopup><div>{error.message}</div></ErrorPopup>);
            })
        }
    }, [setLogDetail, navigate, openPopup, prjId, lId]);



    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {
        getLogDetails();
        getLogsCorrelated();
    }, []);

    useEffect(() => {
        var element = document.getElementById("description");
        if (element !== null) {
            if (logDetail?.description === undefined || logDetail?.description === "" || logDetail?.description === null) {
                element.innerHTML = "No description provided."
                return 
            } else {
                element.innerHTML = JsonStringHelper.FormatString(logDetail?.description)
            }
        }
    }, [logDetail?.description])


    const logLevelValue = useMemo(() => {
        if (logDetail?.level) {
            return LogLevel[logDetail.level];
        }
    }, [logDetail?.level]);

    const totalLogsCorrelated = useMemo(() => logsCorrelated?.length, [logsCorrelated]);


    /****************************
     * USER ACTIONS
     *****************************/

    const openLogDetail = useCallback((col: ColDef) => {
        const openNextLogDetail = (index: number) => {
            const nextLog = logsCorrelated.at(index);
            if (!nextLog) { return };
            openLogDetail(nextLog);
        };

        openPopup(
            <DetailCorrelatedLogPopup
                correlatedLog={col.log}
                logLevel={col.logLevel}
                totalLogs={totalLogsCorrelated}
                onCurrentLogIndexChanged={(idx) => openNextLogDetail(idx)}
                currentIndex={col.currentIndex}
            />
        );
    }, [openPopup, logsCorrelated, totalLogsCorrelated]);

    const handleLogClicked = useCallback((log: ColDef) => {
        openLogDetail(log);
    }, [openLogDetail, totalLogsCorrelated]);

    /****************************
     * CSS & HTML
     *****************************/

    const logLevelCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .addConditional(logDetail?.level, "log-level")
            .addConditional(LogLevel.INFO === logDetail?.level, "primary-color")
            .addConditional(LogLevel.FATAL === logDetail?.level || LogLevel.ERROR === logDetail?.level || LogLevel.CRITICAL_ERROR === logDetail?.level, "red-color")
            .addConditional(LogLevel.DEBUG === logDetail?.level || LogLevel.TRACE === logDetail?.level || LogLevel.UNKNOWN === logDetail?.level, "grey-color")
            .build();
    }, [logDetail?.level]);

    const rowDefinition = useMemo((): RowDefinition<ColDef> => ({
        onClick: handleLogClicked
    }), [handleLogClicked]);

    const columns: ColumnDefinition<ColDef>[] = useMemo(() => [
        {
            cellRenderProp: (v) => DateTimeHelper.formatDateTime(v.log.timeStamp),
            width: "12%",
            headerRender: <>Date</>,
            isSortable: false,
        },
        {
            cellRenderProp: (v) => v.log.componentName || v.log.componentId,
            width: "15%",
            headerRender: <>Component</>,
            isSortable: false,
        },
        {
            cellRenderProp: (v) => v.log.operationName,
            width: "30%",
            headerRender: <>Operation</>,
            isSortable: false,
        },
        {
            cellRenderProp: (v) => v.log.message,
            width: "24%",
            headerRender: <>Message</>,
            isSortable: false,
        },
        {
            cellRenderProp: (v) => getLogLevelNameById(v.log.level),
            width: "7%",
            headerRender: <>Level</>,
            isSortable: false,
        },
        {
            cellRenderProp: (v) => <DetailCellRenderer onClick={() => handleLogClicked(v)} />,
            width: "3%",
            headerRender: <></>,
            isSortable: false,
        },
    ], [handleLogClicked])



    return (
        <PageContainer className="log-details-page">
            <Spacer mode="vertical" px="28.5" />

            <FlexLayout direction={"horizontal"} >
                <div>
                    <h1 title={logDetail?.message || ""} className="log-title">{logDetail?.message}</h1>
                </div>
                <Spacer mode="horizontal" px="30" />

                <div className={logLevelCss}>
                    <div className="circle"></div>
                    <div className="description">{logLevelValue}</div>
                </div>
            </FlexLayout>

            <Spacer mode="vertical" px="21" />
            <h4>Description</h4>
            <Spacer mode="vertical" px="8" />
            
            <div id="description" className="log-description"></div>
            
            <Spacer mode="vertical" px="12" />
            <LineSeparator />
            <Spacer mode="vertical" px="12" />

            <Table<ColDef>
                items={logsCorrelated || []}
                columnDefinitions={columns}
                totalItems={totalLogsCorrelated || 0}
                isLoading={initLoader || getLogsCorrelatedCall.isLoading}
                rowDefinition={rowDefinition}
            ></Table>
        </PageContainer>
    );
}




