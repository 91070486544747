import "./ProjectsHomePage.css";

import {
  ColumnDefinition,
  IOrderInfo,
  RowDefinition,
  Table,
} from "../../../../lib/components/table/Table";
import { DetailCell, NameCell, OptionsCell, StatsCell } from "./Cells";
import { ProjectDTO, RecordDTO } from "../../models/api/ProjectGetAllResponseDTO";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useInitLoader, useServiceCall } from "../../../../lib/hooks/useServiceCall";
import { useNavigate, useSearchParams } from "react-router-dom";

import { AddProjectPopup } from "./AddProjectPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { CriticalProjectCard } from "./CriticalProjectCard";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { PlusSVG } from "../../../../lib/assets/icons";
import { Project } from "../../models/domain/project";
import { ProjectService } from "../../services/ProjectService";
import { ProjectsChangedEvent } from "../../utils/ProjectsChangedEvent";
import { ProjectsQueryOptions } from "../../../../common/models/ProjectsQueryOptions";
import { useFormControl } from "../../../../lib/components/form/Form";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";

//import "reactjs-popup/dist/index.css";

var projectSvc = new ProjectService();

export interface TempCardProperties {
  projectId: number;
  projectName: string;
  numberOfErrors: number;
  numberOfSuccess: number;
  totalFails: number;
}

export const projectTableColumns: ColumnDefinition<Project>[] = [
  {
    cellRenderProp: (v) => <NameCell value={v} />,
    width: "40%",
    headerRender: <>Name</>,
    columnKey: "Name",
    isSortable: true,
  },
  {
    cellRenderProp: (v) => <StatsCell value={v} />,
    width: "40%",
    headerRender: <>Stats</>,
    columnKey: "stats",
    isSortable: false,
  },
  {
    cellRenderProp: (v) => <OptionsCell value={v} />,
    width: "15%",
    headerRender: <></>,
    columnKey: "options",
    isSortable: false,
  },
  {
    cellRenderProp: (v) => <DetailCell value={v} />,
    width: "5%",
    headerRender: <></>,
    columnKey: "detail",
    isSortable: false,
  }
];

/****************************
* MAPPERS
*****************************/

const mapRecordDtoToProject = (res: RecordDTO): Project => ({
  id: res.project.id,
  externalId: res.project.externalId,
  name: res.project.name,
  failuresInLast24h: res.failuresInLast24h,
  activeComponents: res.actives,
  inactiveComponents: res.inactives
})

export function ProjectsHomePage() {
  const cardsRef = useRef(null);
  const navigate = useNavigate();
  const openPopup = usePopup();
  const [searchParams] = useSearchParams();

  const [projects, setProjects] = useState<Project[]>([]);
  const [criticalProjects, setCriticalProjects] = useState<Project[]>([]);

  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
    {
      columnKey: "Name",
      direction: "ASC",
    }
  ]);
  const initLoader = useInitLoader();

  const getProjectsCall = useServiceCall(projectSvc.getProjects)
  const getCriticalProjectsCall = useServiceCall(projectSvc.getCriticalProjects);

  /** Filter form controls **/
  const nameFilterFormControl = useFormControl<string>({ initialValue: searchParams.get("name") ?? undefined });

  /****************************
  * DATA REQUESTS
  *****************************/

  const getProjects = useCallback(() => {
    const request : ProjectsQueryOptions = {
      page: currentPage,
      pageLength: itemsPerPage,
      column: orderInfoColumns[0]?.columnKey || 'Name',
      isDescending: orderInfoColumns[0]?.direction === 'DESC' || false,
      name: nameFilterFormControl.value
    }

    getProjectsCall.invoke(request).then((res) => {
      setProjects(res.records.map(mapRecordDtoToProject));
      setTotalItems(res.totalItems)
    });

  }, [getProjectsCall, currentPage, itemsPerPage, orderInfoColumns, nameFilterFormControl, setProjects, setTotalItems]);


  const getCriticalProjects = useCallback(() => {
    getCriticalProjectsCall.invoke().then((res) => setCriticalProjects(res.map(mapRecordDtoToProject)));
  }, [getCriticalProjectsCall, setCriticalProjects]);


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/
  useEffect(() => {
    if (!initLoader && currentPage === 0) {
      getProjects();
      getCriticalProjects();
    } else {
      setCurrentPage(0)
    }
  }, [itemsPerPage]);

  useEffect(() => {
    getProjects();
    getCriticalProjects();
  }, [currentPage, orderInfoColumns]);

  useEffect(() => {
    const handleProjectsEvent = () => {
      getProjects();
      getCriticalProjects();
    }

    ProjectsChangedEvent.attachHandler(handleProjectsEvent);
    return () => { ProjectsChangedEvent.detachHandler(handleProjectsEvent); };
  }, [getProjects, getCriticalProjects]);


  const paginationOptions = useMemo(() => ({
    itemsPerPage: itemsPerPage,
    itemsPerPageOptions: [10, 20, 50],
    onItemsPerPageChanged: setItemsPerPage,
  }), [setItemsPerPage, itemsPerPage])

  const calculateNumberOfCards = useMemo(() => {
    // todo: this should not be dependent of critical Projects to update size , needs to be fixed
    const getWidth = () => {
      const divElement = cardsRef.current;
      if (divElement) {
        const computedStyle = window.getComputedStyle(divElement);
        return parseFloat(computedStyle.width) || 0;
      }
      return 0;
    };

    const divWidth = getWidth();
    const cardWidth = 330;

    return Math.floor(divWidth / cardWidth);
  }, [criticalProjects]);


  /****************************
  * USER ACTIONS
  *****************************/
  const handleClickSearchProjects = useCallback(() => {
    getProjects();
  }, [getProjects]);

  const handleOnOrderChanged = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderedPriorities);
    }, []);

  const handleOnCurrentPageChanged = useCallback(
    (page: number) => {
      setCurrentPage(page)
    }, [setCurrentPage]);

  const handleAddProjectClicked = useCallback(() => {
    openPopup(<AddProjectPopup onCompleted={() => { getProjects(); getCriticalProjects() }} />)
  }, [openPopup, getProjects]);

  const handleProjectClicked = useCallback((value: Project, eventButtonId?: number) => {
    switch (eventButtonId) {
      case 0: //right click
        navigate(value.id + '/');
        break;
      case 1: //middle mouse
        window.open(`${window.location.origin}${window.location.pathname}/${value.id}/`, '_blank');
        break

    }
  }, [navigate]);

  /****************************
  * CSS & HTML
  *****************************/

  const renderDetailCards = useMemo(() => {
    return criticalProjects
      .slice(0, calculateNumberOfCards)
      .map((item, idx) => (<CriticalProjectCard idx={idx} project={item} />));
  }, [criticalProjects, calculateNumberOfCards]);

  const rowDefinition = useMemo((): RowDefinition<Project> => ({
    onClick: handleProjectClicked
  }), [handleProjectClicked]);

  return (
    <PageContainer className="projects-page">
      <div className="page-header">

        <h1>Projects</h1>
        <Button
          className="addproject-button"
          icon={<PlusSVG />}
          text="Add Project"
          type="primary"
          onClick={handleAddProjectClicked}
        />
      </div>

      <div ref={cardsRef} className="cards">
        {renderDetailCards}
      </div>
      <div className="table">
        <div className="filter">
          <FormFieldTextInput label="Name" formControl={nameFilterFormControl} placeholder="Insert the project name" />
          <Button className="search-button" text="Search" type="secondary" onClick={() => handleClickSearchProjects()} />
        </div>
        <Table
          items={projects}
          columnDefinitions={projectTableColumns}
          totalItems={totalItems}
          orderColumns={initLoader || getProjectsCall.isLoading ? [] : orderInfoColumns}
          paginationOptions={paginationOptions}
          currentPage={currentPage}
          onCurrentPageChanged={handleOnCurrentPageChanged}
          isLoading={initLoader || getProjectsCall.isLoading}
          onOrderChanged={handleOnOrderChanged}
          rowDefinition={rowDefinition}
        ></Table>
      </div>
      {/* <div className="nivo">
        <NivoLine></NivoLine>
        <NivoPie></NivoPie>
      </div> */}
    </PageContainer>
  );
}
