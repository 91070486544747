import { ILanguage } from "./ILanguage";

export const languagePT: ILanguage = {
  COMMON: {
    ProjectName: "AURORA",
    Continue: "Continuar",
    Yes: "Sim",
    No: "Não",
    Cancel: "Cancelar",
    Create: "Criar",
    Add: "Adicionar",
    Import: "Importar",
    Change: "Alterar",
    Suspend: "Suspender",
    Save: "Guardar",
    Edit: "Editar",
    Delete: "Apagar",
    Remove: "Remover",
    Deactivate: "Desativar",
    Activate: "Ativar",
    Logout: "Terminar Sessão",
    Optional: "Opcional",
    Registar: "Registar",
    PrepositionForDateTime: " a ",
    CreatedBy: "Registado por",
    CreatedOn: "Registada a",
    All: "Todos",
    Clear: "Limpar",
    Deliver: "Entregar",
    OtherActions: "Outras ações",
    CloneAction: "Clonar Amostra",
    CloneRequestAction: "Clonar Pedido",
    SelectOneOptionPlaceholder: "Selecione uma opção",
    Name: "Nome",
    Back: "Voltar",

    FORM: {
      Details: "Detalhes",
      Search: "Pesquisar",
      SearchPlaceholder: "Pesquisar",
      InitialDate: "Data Inicial",
      InitialDatePlaceholder: "Data Inicial",
      InitialCreationDatePlaceholder: "Data Inicial de Criação",
      FinalDate: "Data Final",
      FinalDatePlaceholder: "Data Final",
      FinalCreationDatePlaceholder: "Data Fim de Criação",
      RequestDatePlaceholder: "Selecione uma data",
      DefaultPlaceholder: "Digite Aqui",
      SelectDefaultPlaceholder: "Digite para pesquisar",
      SelectStatusPlaceholder: "Selecione estados",
      Filters: "Filtros",
      CleanFilters: "Limpar Filtros",
      ClearSelection: "Limpar seleção",
      Sunday: "Do",
      Monday: "Se",
      Tuesday: "Te",
      Wednesday: "Qa",
      Thursday: "Qi",
      Friday: "Sx",
      Saturday: "Sa",
      MoreOptionsBtn: "Ver mais...",

      VALIDATIONS: {
        CannotBeEmpty: "Este campo não pode estar vazio.",
        RequiredField: "Este campo é obrigatório.",
        GuidValidator: "Formato GUID inválido.",
        SelectOneOption: "Selecione uma opção.",
        BatchFormatError: "Deverá conter o seguinte formato: ####### ou #######/# ou #######/##",
        FillMandatoryFields: "Por favor preencha os campos obrigatórios!",
        InvalidLocationId: "O ID da localização é inválido.",
        InvalidRequestId: "O ID do pedido é inválido.",
        PositiveNumber: "Insira um número inteiro",
        PositiveNumberOrDecimal: "Insira um número positivo / decimal",
        SelectDate: "Por favor selecione uma data",
      }
    },

    ERRORPAGES: {
      SomethingWrong: "Ocorreu um erro",
      BackToHome: "Página Inicial",
      AccessDenied: "Acesso Negado",
      AccessDeniedText1: "Não tem permissão para ver esta página!",
      AccessDeniedText2: "Por favor, verifique as suas credenciais e tente novamente.",
      PageNotFound: "Página Não Encontrada",
      PageNotFoundText: "Não conseguimos encontrar a página que procura.",
      SessionExpired: "Sessão Expirada",
      SessionExpiredText: "A sessão expirou devido à sua inatividade",
      Login: "Login",
    },

    POPUPS: {
      ImportOptions: "Opções de Importação",
      ExcelTemplate: "Template Excel",
      ExcelTemplateText: "Clique no botão abaixo para fazer o download de um ficheiro modelo de Excel. Este modelo contém a estrutura correta para a importação de dados.",
      Import: "Importar",
      ImportText: "Clique no botão abaixo para selecionar um ficheiro Excel e iniciar o processo de importação. Certifique-se que seleciona o ficheiro correto.",
    },
    SYSTEMPOPUPS: {
      Error: "Erro!",
      ErrorText: "",
      Success: "Sucesso!",
      SuccessText: "",
      Warning: "Aviso!",
      WarningText: "",
    },

    TABLE: {
      NoResults: "Nenhum resultado encontrado",
      NoResultsText: "Não encontramos o que procura, por favor procure novamente",
      RowsPerPage: "Items por pág.",
      Of: "de",
    }
  },
};
