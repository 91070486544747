/* eslint-disable no-unreachable */

import "./Table.css";

import { ITableRowProps as ITableRow, TableRow } from "./table-row/TableRow";
import { PaginationOptions, TableFooter } from "./table-footer/TableFooter";
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { ArrayHelper } from "../../utils/ArrayHelper";
import { ReactComponent as ArrowDownSVG } from "../../assets/icons/arrowDown.svg";
import { ReactComponent as ArrowUpSVG } from "../../assets/icons/arrowUp.svg";
import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder";
import { Empty } from "./empty/Empty";
import { ITableCellProps } from "./table-cell/TableCell";
import { Loader } from "../loader/Loader";
import { isNull } from "../../utils/ValidationChecks";

export interface ColumnDefinition<T> {
    width?: string;
    cellRenderProp: (value: T) => React.ReactNode;
    headerRender?: React.ReactNode;
    columnKey?: string;
    isSortable?: boolean;
}

export interface RowDefinition<T> {
    onClick: (value: T, eventButton?: number) => void;
}

export interface IKeyLabel {
    key: number;
    label: number;
}

export interface IOrderInfo {
    direction: "ASC" | "DESC" | "NONE";
    columnKey: string;
}

interface ITableProps<T> {
    className?: string;
    items: T[];
    columnDefinitions: ColumnDefinition<T>[];
    rowDefinition?: RowDefinition<T>;
    totalItems: number;
    currentPage?: number;
    paginationOptions?: PaginationOptions;
    isLoading?: boolean;
    maxBodyHeight?: number;
    orderColumns?: IOrderInfo[];
    onOrderChanged?: (orderInfos: IOrderInfo[]) => void;
    onItemsPerPageChanged?: (rowsPerPage: number) => void;
    onCurrentPageChanged?: (newPage: number) => void;
}

export function Table<T>(props: ITableProps<T>) {
    const tableRef = useRef<HTMLTableElement>(null);
    const [orderInfos, setOrderInfos] = useState<IOrderInfo[]>([]);

    useEffect(() => {
        setOrderInfos(props.orderColumns || []);
    }, [props.orderColumns]);

    /****************************
     * DATA MANIPULATION EFFECTS *
     *****************************/

    const defaultColumnWidth = useMemo(() => {
        const tableWidth = tableRef.current?.offsetWidth || 0;

        const columnWithoutWidthCount = props.columnDefinitions.filter(
            (col) => col.width === undefined
        ).length;

        if (columnWithoutWidthCount === 0) {
            return 0;
        }

        const tableWidthOcupated = props.columnDefinitions.reduce((sum, col) => {
            let columnWidth = 0;
            if (col.width?.includes("%")) {
                columnWidth = parseInt(col.width.split("%")[0]);
            } else if (col.width?.includes("px")) {
                let columnWidthPx = parseInt(col.width.split("px")[0]);
                columnWidth = (columnWidthPx * 100) / tableWidth;
            }
            return (sum += columnWidth);
        }, 0);

        const defaultWidth = (100 - tableWidthOcupated) / columnWithoutWidthCount;

        return defaultWidth + "%";
    }, [props.columnDefinitions, tableRef]);

    const hasItems = useMemo(() => props.items.length > 0, [props.items]);

    const isFooterDisabled = useMemo(() => ((props.isLoading && props.currentPage === 0) || !hasItems), [props.isLoading, props.currentPage, hasItems]);

    /****************************
     * USER ACTIONS
     *****************************/

    const handleSortColumnClick = useCallback(
        (columnKey: string | undefined) => {
            if (!columnKey) return;

            let newColumnOrder: IOrderInfo = { columnKey: columnKey, direction: "NONE" };

            const oldColumnOrderIdx = orderInfos.findIndex((ord) => ord.columnKey === columnKey);

            var oldColumnOrder = oldColumnOrderIdx > -1
                ? orderInfos.at(oldColumnOrderIdx)
                : undefined;

            switch (oldColumnOrder?.direction) {
                case "ASC":
                    newColumnOrder.direction = "DESC";
                    break;
                case "DESC":
                    newColumnOrder.direction = "NONE";
                    break;
                default:
                    newColumnOrder.direction = "ASC";
                    break;
            }

            let newOrderState: IOrderInfo[] = orderInfos.filter((ord) => ord.columnKey !== columnKey);

            switch (newColumnOrder.direction) {
                case "ASC":
                    newOrderState.push(newColumnOrder);
                    break;
                case "DESC":
                    ArrayHelper.insertIntoPosition(newOrderState, oldColumnOrderIdx, newColumnOrder);
                    break;
                default:
                    break;
            }

            setOrderInfos(newOrderState);
            props.onOrderChanged && props.onOrderChanged(newOrderState);
        },
        [
            orderInfos,
            setOrderInfos,
            props.onOrderChanged,
        ]
    );

    const totalColumns = useMemo(() => {
        return props.columnDefinitions.length;
    }, [props.columnDefinitions]);


    /****************************
     * CSS && HTML
     *****************************/

    const tableCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("table-container")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className]);



    const theaderCss = useMemo((): React.CSSProperties | undefined => {
        let nrOfHeaderColumnsToRender = props.columnDefinitions.filter(
            (col) => !isNull(col.headerRender)
        ).length;
        if (nrOfHeaderColumnsToRender !== 0) return undefined;
        return { height: 0 };
    }, [props.columnDefinitions]);

    const tableHeader = useMemo(() => {
        let nrOfHeaderColumnsToRender = props.columnDefinitions
            .filter((col) => !isNull(col.headerRender)).length;

        return props.columnDefinitions.map((col, idx) => {
            const thCss: React.CSSProperties = {
                width: col.width || defaultColumnWidth,
            };
            if (nrOfHeaderColumnsToRender === 0) thCss.padding = 0;

            const orderInfo = orderInfos.find(
                (order) => order.columnKey === col?.columnKey
            );

            let arrow = <></>;
            switch (orderInfo?.direction) {
                case "ASC":
                    arrow = <ArrowUpSVG />;
                    break;
                case "DESC":
                    arrow = <ArrowDownSVG />;
                    break;
                default:
                    arrow = <ArrowUpSVG />;
            }

            var hasOrder = col.isSortable;

            const tableColumnHeaderCss = CssClassNameBuilder.new()
                .add("table-column-header")
                .addConditional(hasOrder, "header-clickable")
                .addConditional(props.className, props.className)
                .build();

            const orderbyCss = CssClassNameBuilder.new()
                .add("orderby-icon")
                .addConditional(
                    hasOrder && !orderInfo,
                    "orderby-neutral"
                )
                .addConditional(
                    hasOrder && orderInfo,
                    "show-arrow"
                )
                .build();
            return (
                <th style={thCss} key={idx}>
                    <div
                        className={tableColumnHeaderCss}
                        onClick={() => (hasOrder ? handleSortColumnClick(col.columnKey) : null)}
                    >
                        {col.headerRender}
                        <div className={orderbyCss}>{hasOrder ? arrow : null}</div>
                    </div>
                </th>
            );
        });
    }, [
        props.columnDefinitions,
        defaultColumnWidth,
        handleSortColumnClick,
        orderInfos,
        props.className
    ]);

    const rows = useMemo(() => {
        let mappedRows = props.items.map(
            (item): ITableRow => ({
                cells: props.columnDefinitions.map(
                    (column): ITableCellProps => ({
                        children: column.cellRenderProp && column.cellRenderProp(item),

                    })
                ),
            })
        );

        return props.items.map((item, idx) => {
            const cells = props.columnDefinitions.map((column): ITableCellProps => (
                {
                    children: column.cellRenderProp && column.cellRenderProp(item)
                }));

            const handleRowClicked = (eventButtonId?: number) => props.rowDefinition && props.rowDefinition.onClick(props.items[idx], eventButtonId);

            return <TableRow
                key={idx}
                cells={cells}
                // onClick={() => props.rowDefinition && props.rowDefinition.onClick(props.items[idx])}
                onClick={props.rowDefinition?.onClick ? handleRowClicked : undefined}
            ></TableRow>

        }
        );
    }, [props.items, props.columnDefinitions, props.rowDefinition]);



    return (
        <div className={tableCss}>
            <table ref={tableRef}>
                <thead style={theaderCss}>
                    <tr>{tableHeader}</tr>
                </thead>
                {props.isLoading ? (
                    <div className="table-loader-container">
                        <Loader></Loader>
                    </div>
                ) : (
                    hasItems ?
                        <tbody>{rows}</tbody>
                        :
                        <Empty numberOfColumns={totalColumns} />
                )}
                {isFooterDisabled ? null : (
                    <tfoot>
                        <TableFooter
                            columnCount={tableHeader.length}
                            currentPage={props.currentPage}
                            paginationOptions={props.paginationOptions || undefined}
                            totalItems={props.totalItems}
                            onCurrentPageChanged={props.onCurrentPageChanged}
                        ></TableFooter>
                    </tfoot>
                )}
            </table>
        </div>
    );
}
