import {HubConnection, HubConnectionBuilder} from "@microsoft/signalr";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { LogDTO } from "../../../common/models/LogDTO";

const Route = (path: string) => {
    return ConfigurationProvider.getConfiguration().App.BackendUrl + path;
};



export class RealtimeLogsEvent extends Event {
    logs: LogDTO[]; // Replace 'any' with the actual type of your logs data
  
    constructor(eventName: string, logs: LogDTO[]) {
      super(eventName);
      this.logs = logs;
    }
  }

export class RealtimeProvider {

    private static connection: HubConnection;

    static init() {
        RealtimeProvider.connection = new HubConnectionBuilder()
        .withUrl(Route("/realtime/logs"))
        .build();

        RealtimeProvider.connection.on(`SendLogs`, (logs: LogDTO[]) => {
            const event = new RealtimeLogsEvent('realtime-logsReceived', logs);
            // console.log("SendLogs received",logs);
            window.dispatchEvent(event);
        });

        RealtimeProvider.connection.start();

    }

    static subscribeGroup(groupName: string){
        RealtimeProvider.connection.send("joinGroup", groupName)
    }

    static unSubscribeGroup(groupName: string){
        RealtimeProvider.connection.send("removeGroup", groupName)
    }

}
