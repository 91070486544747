import './FlexLayout.css';

import { CssClassNameBuilder } from '../../../utils/CssClassNameBuilder';
import { useMemo } from 'react';

export interface IStackLayoutProps {
    direction: 'horizontal' | 'vertical';
    horizontalAlign?: 'start' | 'center' | 'end';
    verticalAlign?: 'start' | 'center' | 'end';
    className?: string;
    children: React.ReactNode;
}

export function FlexLayout(props: IStackLayoutProps) {

    const stackLayoutCss = useMemo(() => {
        let horizontalAlign = props.horizontalAlign;
        let verticalAlign = props.verticalAlign;
        return CssClassNameBuilder.new()
            .add("flex-layout")
            .add(props.direction)
            .addConditional(horizontalAlign, "horizontal-" + horizontalAlign)
            .addConditional(verticalAlign, "vertical-" + verticalAlign)
            .addConditional(props.className, props.className)
            .build();
    }, [props.direction, props.horizontalAlign, props.verticalAlign, props.className]);


    return (
        <div className={stackLayoutCss}>{props.children}</div>
    )

}
