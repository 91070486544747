import {AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { Logger } from "../logger/Logger";
import { MetricService } from "../../../features/projects/services/MetricService";

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    if(config.url?.includes("/api/Metrics") || config.url?.includes("/api/Logs")) {
        return config;
    }
    var headersClone = {...config.headers};
    headersClone["Authorization"] = "";
    var correlationId = headersClone["AURORA_CORRELATION_ID"];
    var labels = {
        method: config.method,
        url: config.url
    }
    var info = {
        url: config.url,
        headers: headersClone
    }
    MetricService.getInstance().metric(`HTTP_REQUEST`, JSON.stringify(labels), null);
    Logger.getInstance().info(`${config?.method?.toUpperCase()} /api${config?.url?.split("/api")[1]}`,JSON.stringify(info),"HTTP_REQUEST", correlationId);
    return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    if(error.config?.url?.includes("/api/Metrics") || error.config?.url?.includes("/api/Logs")) {
        return Promise.reject(error);
    }
    var headersClone = {...error.config?.headers};
    headersClone["Authorization"] = "";
    var correlationId = headersClone["AURORA_CORRELATION_ID"];
    var info = {
        url: error.request?.config.url,
        status: error.request?.status,
        message: error.message,
        data: error.request?.data,
        headers: headersClone
    }
    var labels = {
        method: error.config?.method,
        url: error.request?.config.url
    }
    MetricService.getInstance().metric(`HTTP_REQUEST_STATUS`, JSON.stringify(labels), JSON.stringify(error?.request.status));
    Logger.getInstance().error(`HTTP ${JSON.stringify(error.request?.status)} :: ${error.config?.method?.toUpperCase()} /api${error.config?.url?.split("/api")[1]}`,JSON.stringify(info),"HTTP_REQUEST", correlationId);
    return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
    if(response.config.url?.includes("/api/Metrics") || response.config.url?.includes("/api/Logs")) {
        return response;
    }
    var headersClone = {...response.config.headers};
    headersClone["Authorization"] = "";
    var correlationId = headersClone["AURORA_CORRELATION_ID"];
    var labels = {
        method: response.config?.method,
        url: response?.config?.url
    }
    var info = {
        url: response?.config?.url,
        status: response.request?.status,
        headers: headersClone
    }
    MetricService.getInstance().metric(`HTTP_RESPONSE_STATUS`, JSON.stringify(labels), JSON.stringify(response?.status));
    if(response.request?.status >= 300) {
        Logger.getInstance().debug(`HTTP ${JSON.stringify(response.request?.status)} :: ${response.config?.method?.toUpperCase()} /api${response.config?.url?.split("/api")[1]}`,JSON.stringify(info),"HTTP_RESPONSE", correlationId);
    } else {
        Logger.getInstance().info(`HTTP ${JSON.stringify(response.request?.status)} :: ${response.config?.method?.toUpperCase()} /api${response.config?.url?.split("/api")[1]}`,JSON.stringify(info),"HTTP_RESPONSE", correlationId);
    }
    return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
    if(error.config?.url?.includes("/api/Metrics") || error.config?.url?.includes("/api/Logs")) {
        return Promise.reject(error);
    }
    var headersClone = {...error.config?.headers};
    headersClone["Authorization"] = "";
    var correlationId = headersClone["AURORA_CORRELATION_ID"];
    var info = {
        url: error.config?.url,
        status: error.request?.status,
        message: error.message,
        data: error.response?.data,
        headers: headersClone
    }
    var labels = {
        method: error.config?.method,
        url: error.config?.url
    }
    MetricService.getInstance().metric(`HTTP_RESPONSE_STATUS`, JSON.stringify(labels), JSON.stringify(error?.request.status));
    if(error.request?.status === 500)
        Logger.getInstance().criticalError(`HTTP ${JSON.stringify(error.request?.status)} :: ${error.config?.method?.toUpperCase()} /api${error.config?.url?.split("/api")[1]}`,JSON.stringify(info),"HTTP_RESPONSE", correlationId);
    else
        Logger.getInstance().error(`HTTP ${JSON.stringify(error.request?.status)} :: ${error.config?.method?.toUpperCase()} /api${error.config?.url?.split("/api")[1]}`,JSON.stringify(info),"HTTP_RESPONSE", correlationId);
    return Promise.reject(error);
}

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
}

export class Interceptors{}