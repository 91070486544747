import { EditSVG, HeartSVG, RemoveSVG } from "../../../../lib/assets/icons";

import { Component } from "../../domain/Component";
import { ComponentService } from "../../services/ComponentService";
import { ComponentsChangedEvent } from "../../utils/ComponentsChangedEvent";
import { EditComponentHealthPopup } from "./EditComponentHealthPopup";
import { EditComponentPopup } from "./EditComponentPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { useCallback } from "react";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";

var componentSvc = new ComponentService();

interface IComponentOptionsCellProps {
    value: Component
}

export const ComponentOptionsCell = (props: IComponentOptionsCellProps) => {
    const openPopup = usePopup();


    /****************************
    * USER ACTIONS
    *****************************/

    const handleEditComponentHealthClicked = useCallback(() => {
        openPopup(
            <EditComponentHealthPopup
                projectId={props.value.parentProject}
                componentId={props.value.id}
                onCompleted={() => ComponentsChangedEvent.fireComponentsChanged()}
            />)
    }, [props.value.parentProject, props.value.id, openPopup]);

    const handleEditComponentClicked = useCallback(() => {
        openPopup(
            <EditComponentPopup
                projectId={props.value.parentProject}
                componentId={props.value.id}
                onCompleted={() => ComponentsChangedEvent.fireComponentsChanged()}
            />)
    }, [props.value.parentProject, props.value.id, openPopup]);


    const handleDeleteOnConfirmClicked = useCallback(() => {
        componentSvc
            .deleteComponent(props.value.parentProject, props.value.id)
            .then(() => {
                openPopup(
                    <SuccessPopup>Component deleted successfully!</SuccessPopup>
                );
                ComponentsChangedEvent.fireComponentsChanged();
            })
            .catch((error) => {
                if (error.response.data) {
                    openPopup(<ErrorPopup>{error.response.data}</ErrorPopup>);
                } else {
                    openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
                }
            });
    }, [props.value.parentProject, props.value.id, openPopup]);


    const handleRemoveComponentClicked = useCallback(() => {
        openPopup(
            <WarningPopup
                onConfirmClicked={handleDeleteOnConfirmClicked}>
                    "Are you sure you want to delete this component?"
            </WarningPopup>
        )
    }, [handleDeleteOnConfirmClicked, openPopup]);

    /****************************
    * CSS & HTML
    *****************************/

    return (
        <div className="options-cell">
            <HeartSVG onClick={handleEditComponentHealthClicked} />
            <EditSVG onClick={handleEditComponentClicked} />
            <RemoveSVG onClick={handleRemoveComponentClicked} />
        </div>
    );
};