import "./SideBarButton.css";
import { NavLink } from "react-router-dom";
import { MenuItem } from "./MenuItem";

const isActive = (props: { isActive: boolean; isPending: boolean }) =>
    props.isActive ? "selected" : "is-pending";


export function SideBarButton(props: MenuItem) {
    return (
        <div className="icon">
            <NavLink to={props.navigateTo} className={isActive}>
                {props.icon}
            </NavLink>
        </div>
    );
}
