import "./SpaceBackgroundLayout.css"

import { ReactNode, useEffect } from "react";

interface IMainBackgroundLayoutProps {
    children: ReactNode
}

export function SpaceBackgroundLayout(props: IMainBackgroundLayoutProps){




    return (
        <div className="space-background-layout">
            <div className="sky">
                <div className="text"> <div className="inner">{props.children}</div> </div>
                <div className="stars"/>
                <div className="stars1"/>
                <div className="stars2"/>
                <div className="shooting-stars"/>
            </div>

        </div>
    )

}
