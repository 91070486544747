import { DateTime } from "luxon";
import { MetricDTO } from "../../../common/models/MetricDTO";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { DailyHttpRequestsByMethodDto } from "../../../common/models/DailyHttpRequestsByMethodDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { UrlUtility } from "../../../lib/utils/UrlUtility";
import { TotalEachFilterWasUsedDTO } from "../../../common/models/TotalEachFilterWasUsedDTO";

const Route = (path: string) => {
    return UrlUtility.join(
        ConfigurationProvider.getConfiguration().App.BackendUrl,
        path
    );
};

export class MetricService {

    // SINGLETON
    static instance: MetricService | null = null;
    static getInstance(): MetricService {
        if (MetricService.instance == null)
            MetricService.instance = new MetricService();
        return MetricService.instance;
    }

    projectId: string;
    componentId: string;
    value: string | undefined;

    constructor() {
        this.projectId = "4bf89363-3679-47a7-b4c9-d74522f8e228";
        this.componentId = "833d7bfe-2966-4f41-a623-517e1bb7d86b";
    }

    metric(name: string, labels: string | null, value: string | null) {
        var metric: MetricDTO = {
            timeStamp: DateTime.now().toString(),
            projectId: this.projectId,
            componentId: this.componentId,
            name: this.formatStrings(name) ?? "",
            labels: labels?.replace(/ /g, "") ?? null,
            value: value
        }
        return HttpClient.sessionRequest<MetricDTO>({
            method: "POST",
            url: "https://aurora.apis.orbitalwatch.dev/api/Metrics",
            data: metric,
        })
            .then((res) => res.data)
            .catch((error) => {
                throw error;
            });
    }

    formatStrings(string: string | null | undefined): string | null {
        return string?.toUpperCase().replace(" ", "_") ?? null;
    }


    getHttpRequestsInLastWeek(): Promise<DailyHttpRequestsByMethodDto[]> {
        return HttpClient.sessionRequest<DailyHttpRequestsByMethodDto[]>({
            method: "GET",
            url: Route(`/api/Metrics/HttpRequestsInLastWeek`),
        })
            .then((res) => res.data)
            .catch((error) => {
                throw error.response?.data;
            });
    }

    getTotalLogPageFiltersUsed(): Promise<TotalEachFilterWasUsedDTO[]> {
        return HttpClient.sessionRequest<TotalEachFilterWasUsedDTO[]>({
            method: "GET",
            url: Route(`/api/Metrics/TotalLogPageFiltersUsed`),
        })
            .then((res) => res.data)
            .catch((error) => {
                throw error.response?.data;
            });
    }
}



