import { useEffect } from "react";

export function useOutsideComponentClickTrigger(ref: any, func: Function) {

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                func()
            }
        }
        window.addEventListener("mousedown", handleClickOutside);
        return () => window.removeEventListener("mousedown", handleClickOutside);
    }, [ref, func]);
}

