import "./Empty.css";

import { ReactComponent as SearchSVG } from "../../../assets/icons/search-icon.svg";

const renderCircles = () => (
    <div id="empty-circles-container">
        <div className="empty-first-circle">
            <div className="empty-second-circle">
                <div className="empty-third-circle">
                    <div className="empty-circle">
                        <div className="empty-circle">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export interface IEmptyProps{
    numberOfColumns: number; 
}



export function Empty(props: IEmptyProps) {
    return (
        <tbody>
            <tr>
                <td colSpan={props.numberOfColumns}>
                    <div className="empty-container">
                        <div className="empty-icon"><SearchSVG /></div>
                        <div className="empty-first-label subtitle">No results found!</div>
                        <div className="empty-second-label">Looks like there are no entries here yet.</div>
                    </div>
                </td>
            </tr>
        </tbody>
    )
}