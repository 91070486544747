import "./TableFooter.css";

import { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as ArrowSVG } from "../../../assets/icons/arrowRight.svg";
import { CssClassNameBuilder } from "../../../utils/CssClassNameBuilder";
import { TableSelectSingle } from "../table-single-select/TableSelectSingle";
import { isNull } from "../../../utils/ValidationChecks";

export interface ITableFooterProps {
    className?: string;
    columnCount: number;
    currentPage?: number;
    totalItems: number;
    paginationOptions?: PaginationOptions
    onCurrentPageChanged?: (newPage: number) => void;
}

export interface PaginationOptions {
    itemsPerPage: number;
    itemsPerPageOptions: number[];
    onItemsPerPageChanged: (rowsPerPage: number) => void;
}

export function TableFooter(props: ITableFooterProps) {
    const [totalPages, setTotalPages] = useState<number>(1);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

    const itemsPerPage = useMemo(() => props.paginationOptions?.itemsPerPage, [props.paginationOptions?.itemsPerPage])

    const hasNextPage = useMemo(() => {
        return props.currentPage === undefined ? false : props.currentPage < totalPages - 1;
    }, [props.currentPage, totalPages]);

    const hasPreviousPage = useMemo(() => {
        return props.currentPage === undefined ? false : props.currentPage > 0;
    }, [props.currentPage]);

    useEffect(() => {
        if (props.totalItems > 0 && itemsPerPage) {
            setTotalPages(Math.ceil(props.totalItems / itemsPerPage));
        }
    }, [props.totalItems, itemsPerPage]);

  /****************************
   * USER ACTIONS
   *****************************/

    const handleNextPageClicked = useCallback(() => {
        if (props.currentPage !== undefined && hasNextPage) {
            props.onCurrentPageChanged && props.onCurrentPageChanged(props.currentPage + 1);
        }
    }, [props.currentPage, props.onCurrentPageChanged, hasNextPage]);

    const handlePreviousPageClicked = useCallback(() => {
        if (props.currentPage !== undefined && hasPreviousPage) {
            props.onCurrentPageChanged && props.onCurrentPageChanged(props.currentPage - 1);
        }
    }, [props.currentPage, props.onCurrentPageChanged, hasPreviousPage]);

  /****************************
   * CSS && HTML
   *****************************/

    const tableFooterCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("table-footer-pagination")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className]);

    const nextButtonCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("table-footer-pagination-button")
            .addConditional(!hasNextPage, "disable")
            .build();
    }, [hasNextPage]);

    const previousButtonCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("table-footer-pagination-button")
            .add("rotate")
            .addConditional(!hasPreviousPage, "disable")
            .build();
    }, [hasPreviousPage]);

    const itemRange = useMemo(() => {
        if (itemsPerPage && props.currentPage !== undefined) {
            let first = itemsPerPage * props.currentPage;
            let second = Math.min(first + itemsPerPage, props.totalItems);
            return (first + 1) + ' - ' + second + ' of ';
        }
        return undefined
    }, [props.totalItems, props.currentPage, itemsPerPage]);


    return (
        <tr>

            <td colSpan={props.columnCount}>

                <div className={tableFooterCss}>
                    {props.paginationOptions ?
                        <TableSelectSingle
                            onChange={props.paginationOptions.onItemsPerPageChanged}
                            options={props.paginationOptions.itemsPerPageOptions}
                            value={props.paginationOptions.itemsPerPage}
                        />
                        : null}

                    <div className="table-footer-pagination-info">{itemRange}{props.totalItems}</div>
                    
                    {!isNull(props.currentPage) ? <div className="table-footer-pagination-buttons">
                        <div className={previousButtonCss} onClick={handlePreviousPageClicked}><ArrowSVG /></div>
                        <div className={nextButtonCss} onClick={handleNextPageClicked}><ArrowSVG /></div>
                    </div>
                        : null}
                </div>
            </td>
        </tr>
    )
}