import './SessionExpiredPage.css';

import {Button} from '../../../lib/components/buttons/Button';
import {SpaceBackgroundLayout} from '../../../lib/layouts/space-background/SpaceBackgroundLayout';
import {useNavigate} from "react-router-dom";

export function SessionExpiredPage() {

    const navigate = useNavigate();


    return (
        <SpaceBackgroundLayout>

            <h1 className="logo-text">Orbital Watch</h1>

            <h1>Session Expired!</h1>
            <div><Button text={"Login"} type="primary" onClick={() => navigate("/identity/login")  } /></div>

        </SpaceBackgroundLayout>
    )
}
