import "./Sidebar.css";

import { MenuItem } from "./MenuItem";
import { SideBarButton } from "./SideBarButton";
import { useMemo } from "react";

export interface ISideBarProps {
    className?: string;
    buttons: MenuItem[];
}



export function Sidebar(props: ISideBarProps) {
    const sideBarButtons = useMemo(() => {
        return props.buttons.map((m, idx) => {
            return (
                <SideBarButton
                    key={idx}
                    icon={m.icon}
                    navigateTo={m.navigateTo}
                ></SideBarButton>
            );
        })

    }, [props.buttons]);

    return (
        <div className="side-bar">
            {sideBarButtons}
        </div>
    );
}

