import "./ComponentsHomePage.css";

import { ColumnDefinition, IOrderInfo, RowDefinition, Table } from "../../../../lib/components/table/Table";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { AddComponentPopup } from "./AddComponentPopup";
import { Button } from "../../../../lib/components/buttons/Button";
import { ComponentNameCell } from "./ComponentNameCell";
import { ComponentOptionsCell } from "./ComponentOptionsCell";
import { ComponentService } from "../../services/ComponentService";
import { ComponentStatusCell } from "./ComponentStatusCell";
import { ComponentsChangedEvent } from "../../utils/ComponentsChangedEvent";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { PlusSVG } from "../../../../lib/assets/icons";
import { ProjectService } from "../../../projects/services/ProjectService";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";
import { useInitLoader, useServiceCallPro } from "../../../../lib/hooks/useServiceCall";
import { Component } from "../../domain/Component";
import { ComponentDTO } from "../../models/ComponentGetAllResponseDTO";


var compontnSvc = new ComponentService();

const DetailCellRenderer = (value: any) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    //navigate(value.value.id + "/components/");    // TO DO: alterar rota para os logs
  };

  return (
    <div className="options-cell">
      {/* <CircleArrowSVG onClick={handleNavigate} /> */}
    </div>
  );
};

const columns: ColumnDefinition<Component>[] = [
  {
    cellRenderProp: (v) => <ComponentNameCell value={v} />,
    width: "40%",
    headerRender: <>Name</>,
    columnKey: "Name",
    isSortable: false,
  },
  {
    cellRenderProp: (v) => <ComponentStatusCell status={v.status} />,
    width: "40%",
    headerRender: <>Stats</>,
    columnKey: "stats",
    isSortable: false,
  },
  {
    cellRenderProp: (v) => <ComponentOptionsCell value={v} />,
    width: "15%",
    headerRender: <></>,
    columnKey: "options",
    isSortable: false,
  },
  {
    cellRenderProp: (v) => <DetailCellRenderer value={v} />,
    width: "5%",
    headerRender: <></>,
    columnKey: "detail",
    isSortable: false,
  },
];

/****************************
* MAPPERS
*****************************/

const mapRecordDtoToComponent = (res: ComponentDTO): Component => ({
  id: res.id,
  externalId: res.externalId,
  name: res.name,
  parentProject: res.parentProject,
  status: res.status,
})

export function ComponentsHomePage() {
  const openPopup = usePopup();
  const navigate = useNavigate();
  const { projectId: id } = useParams();
  const [components, setComponents] = useState<Component[]>([]);
  const [projectName, setProjectName] = useState<string>("");

  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderInfoColumns, setOrderInfoColumns] = useState<IOrderInfo[]>([
    {
      columnKey: "Name",
      direction: "ASC",
    }
  ]);
  const initLoader = useInitLoader();

  const getComponentsCall = useServiceCallPro(compontnSvc.getComponents)

  /****************************
   * DATA REQUESTS
   *****************************/

  const getAllComponents = useCallback(() => {
    const request = {
      page: currentPage,
      pageLength: itemsPerPage,
      column: orderInfoColumns[0]?.columnKey || 'Name',
      isDescending: orderInfoColumns[0]?.direction === 'DESC' || false,
    }

    if (!id) return;

    getComponentsCall.invoke(request, id).then((res) => {
      setComponents(res.records.map(mapRecordDtoToComponent));
      setTotalItems(res.totalItems)
    });

  }, [getComponentsCall, currentPage, itemsPerPage, orderInfoColumns, setComponents, setTotalItems, id]);



  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  useEffect(() => {
    if(currentPage===0) {
      getAllComponents();
    } else {
      setCurrentPage(0)
    }
  }, [itemsPerPage]);

  useEffect(() => {
    getAllComponents();
  }, [currentPage, orderInfoColumns]);

  useEffect(() => {
    const handleComponentsEvent = () => {
      getAllComponents();
    };

    ComponentsChangedEvent.attachHandler(handleComponentsEvent);
    return () => {
      ComponentsChangedEvent.detachHandler(handleComponentsEvent);
    };
  }, [getAllComponents]);

  const paginationOptions = useMemo(() => ({
    itemsPerPage: itemsPerPage,
    itemsPerPageOptions: [10, 20, 50],
    onItemsPerPageChanged: setItemsPerPage,
  }), [setItemsPerPage, itemsPerPage])

  /****************************
   * USER ACTIONS
   *****************************/
  const handleOnOrderChanged = useCallback(
    (orderedPriorities: IOrderInfo[]) => {
      setOrderInfoColumns(orderedPriorities);
    }, []);

  const handleOnCurrentPageChanged = useCallback(
    (page: number) => {
      setCurrentPage(page)
    }, [setCurrentPage]);

  const handleAddComponentClicked = useCallback(() => {
    //MetricService.getInstance().metric("BUTTON_CLICKED_ADD_COMPONENT", "Components Home Page", null)
    openPopup(
      <AddComponentPopup projId={id} onCompleted={() => getAllComponents()} />
    );
  }, [openPopup, id, getAllComponents]);

  // TO DO
  // const handleComponentClicked = useCallback((value: Component) => {
  //   navigate();
  // }, [navigate]);

  /****************************
   * CSS & HTML
   *****************************/

  return (
    <PageContainer className="components-page">
      <Spacer mode="vertical" px="28.5" />
      <FlexLayout direction='horizontal' className="components-page-header" >
        <h1>Components</h1>
        <Button
          className="addcomponent-button"
          icon={<PlusSVG />}
          text="Add Component"
          type="primary"
          onClick={handleAddComponentClicked}
        />
      </FlexLayout>
      <Spacer mode="vertical" px="23" />

      <div>
        <Table
          items={components}
          columnDefinitions={columns}
          totalItems={totalItems} //TODO: Sem paginação por agora
          orderColumns={initLoader || getComponentsCall.isLoading ? [] : orderInfoColumns}
          paginationOptions={paginationOptions}
          currentPage={currentPage}
          onCurrentPageChanged={handleOnCurrentPageChanged}
          isLoading={initLoader || getComponentsCall.isLoading}
          onOrderChanged={handleOnOrderChanged}
        ></Table>
      </div>

    </PageContainer>
  );
}
