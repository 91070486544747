import { DiamondSVG, WarningMessageSVG } from "../../../lib/assets/icons";
import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useSelectedProject, useSetSelectedProject } from "../../../lib/infrastructure/ui/UIServices";

import { ComponentsHomePage } from "../../../features/components/pages/components-home/ComponentsHomePage";
import { LogTracePage } from "../../../features/logs/pages/log-trace/LogTracePage";
import { LogsHomePage } from "../../../features/logs/pages/logs-home/LogsHomePage";

export function LogsModule() {

    return (
        <Routes>
            <Route path="" element={<LogsHomePage />} />
            <Route path="/:logId" element={<LogTracePage />} />
        </Routes>
    );
}