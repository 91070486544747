import "./LogsHomePage.css";

import * as QueryMapper from "./LogsHomeQueryParamHelper";

import { ColumnDefinition, RowDefinition, Table } from "../../../../lib/components/table/Table";
import { ContextCell, LogLevelCell, OpenLogCell } from "./LogsTableCells";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Button } from "../../../../lib/components/buttons/Button";
import { Component } from "../../../components/domain/Component";
import { ComponentSelectOption } from "../../../../common/models/common/ComponentSelectOption";
import { ComponentService } from "../../../components/services/ComponentService";
import { DateTime } from "luxon";
import { DateTimeHelper } from "../../../../common/utils/DateTimeHelper";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from '../../../../lib/layouts/containers/flex/FlexLayout';
import { FormFieldDatePicker } from "../../../../lib/components/form/form-field/FormFieldDatePicker";
import { FormFieldSelectMultiple } from "../../../../lib/components/form/form-field/FormFieldSelectMultiple";
import { FormFieldSelectSingle } from "../../../../lib/components/form/form-field/FormFieldSelectSingle";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { GetLogsRequest } from "../../../../common/models/GetLogsRequest";
import { LogDTO } from "../../../../common/models/LogDTO";
import { LogLevelSelectOption } from "../../../../common/models/common/LogLevelSelectOption";
import { MetricService } from "../../../projects/services/MetricService";
import { PageContainer } from "../../../../lib/layouts/containers/page/PageContainer";
import { ProjectService } from "../../../projects/services/ProjectService";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { useFormControl } from "../../../../lib/components/form/Form";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";

var componentsService = new ComponentService();
var projectsService = new ProjectService();



//TO DO: corrigir ... mapear o enum para um array

export const logLevelOptions: LogLevelSelectOption[] = [
    { key: 0, name: "UNKNOWN" },
    { key: 1, name: "TRACE" },
    { key: 2, name: "DEBUG" },
    { key: 3, name: "INFO" },
    { key: 4, name: "ERROR" },
    { key: 5, name: "FATAL" },
    { key: 6, name: "CRITICAL ERROR" },
];




export const projectLogsColumns: ColumnDefinition<LogDTO>[] = [
    {
        cellRenderProp: (v) => DateTimeHelper.formatDateTime(v.timeStamp),
        width: "8%",
        headerRender: <>Date</>,
        isSortable: false,
    },
    {
        cellRenderProp: (v) => v.componentName,
        width: "13.5%",
        headerRender: <>Component</>,
        isSortable: false,
    },
    {
        cellRenderProp: (v) => <ContextCell log={v} />,
        width: "30%",
        headerRender: <>Context</>,
        isSortable: false,
    },
    {
        cellRenderProp: (v) => v.actor,
        width: "16%",
        headerRender: <>User</>,
        isSortable: false,
    },
    {
        cellRenderProp: (v) => v.message,
        width: "13.5%",
        headerRender: <>Title</>,
        isSortable: false,
    },
    {
        cellRenderProp: (v) => <LogLevelCell log={v} />,
        width: "7%",
        headerRender: <>Level</>,
        isSortable: false,
    },
    {
        cellRenderProp: (v) => v.operationName,
        width: "13.5%",
        headerRender: <>Operation</>,
        isSortable: false,
    },
    {
        cellRenderProp: (v) => <OpenLogCell log={v} />,
        width: "5%",
        headerRender: <></>,
        isSortable: false,
    },
];


/****************************
 * MAPPERS
 *****************************/

const componentLabelSelector = (item: ComponentSelectOption) => item.name;
const componentIdSelector = (item: ComponentSelectOption) => item.key;

const logLevelLabelSelector = (item: LogLevelSelectOption) => item.name;
const logLevelIdSelector = (item: LogLevelSelectOption) => item.key;

function mapComponentDtoToComponentSelectOptions(components: Component[]) {
    var optionsMapped = components.map((obj) => {
        var opMapped: ComponentSelectOption = {
            name: obj.name,
            key: obj.externalId,
        };
        return opMapped;
    });
    return optionsMapped;
}

export function LogsHomePage() {

    const navigate = useNavigate();
    const openPopup = usePopup();
    const { projectId } = useParams();
    const [searchParams] = useSearchParams();

    /** Filter states **/
    const [components, setComponents] = useState<ComponentSelectOption[]>([]);

    /** Table states **/
    const [projectLogs, setProjectLogs] = useState<LogDTO[]>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [itemsPerPage, setItemsPerPage] = useState<number>(parseInt(searchParams.get("pageSize") || "50"));
    const [currentPage, setCurrentPage] = useState<number>(parseInt(searchParams.get("page") || "0"));
    const [totalItems, setTotalItems] = useState(0);


    /** Filter form controls **/
    const componentFilter = useFormControl<ComponentSelectOption>({ initialValue: QueryMapper.mapToComponentSelectOption(searchParams.get("componentId")) });
    const logLevelFilter = useFormControl<LogLevelSelectOption[]>({ initialValue: QueryMapper.mapToLogLevelSelectOption(searchParams.getAll("level")) });
    const initialDateFilterFormControl = useFormControl<DateTime>({ initialValue: QueryMapper.mapToDateTime(searchParams.get("initialDate")) });
    const endDateFilterFormControl = useFormControl<DateTime>({ initialValue: QueryMapper.mapToDateTime(searchParams.get("endDate")) });
    const sessionIdFilterFormControl = useFormControl<string>({ initialValue: searchParams.get("sessionId") ?? undefined });
    const correlationIdFilterFormControl = useFormControl<string>({ initialValue: searchParams.get("correlationId") ?? undefined });
    const actorFilterFormControl = useFormControl<string>({ initialValue: searchParams.get("actor") ?? undefined });
    const operationNameFilterFormControl = useFormControl<string>({ initialValue: searchParams.get("operationName") ?? undefined });


    /****************************
     * DATA REQUESTS
     *****************************/
    const getProjectLogs = useCallback(() => {

        console.log()

        var request: GetLogsRequest = {
            componentId: componentFilter.value?.key,
            sessionId: sessionIdFilterFormControl.value,
            correlationId: correlationIdFilterFormControl.value,
            level: logLevelFilter.value?.map((value) => value.key),
            initialDate: initialDateFilterFormControl.value?.startOf('day'),
            endDate: endDateFilterFormControl.value?.endOf('day'),
            actor: actorFilterFormControl.value,
            operationName: operationNameFilterFormControl.value,
            page: currentPage,
            pageSize: itemsPerPage
        };

        const url = new URL(window.location.origin + window.location.pathname);
        request.componentId && url.searchParams.append('componentId', `${request.componentId}`);
        request.sessionId && url.searchParams.append('sessionId', `${request.sessionId}`);
        request.correlationId && url.searchParams.append('correlationId', `${request.correlationId}`);
        request.level?.forEach(level => {
            request.level && url.searchParams.append('level', `${level}`);
        });
        request.initialDate && url.searchParams.append('initialDate', `${request.initialDate.toUTC().toISO()}`);
        request.endDate && url.searchParams.append('endDate', `${request.endDate.toUTC().toISO()}`);
        request.actor && url.searchParams.append('actor', `${request.actor}`);
        request.operationName && url.searchParams.append('operationName', `${request.operationName}`);
        request.page && url.searchParams.append('page', `${request.page}`);
        request.pageSize && url.searchParams.append('pageSize', `${request.pageSize}`);
        window.history.pushState(null, '', url.toString());

        if (projectId) {
            projectsService
                .getProjectLogs(parseInt(projectId, 10), request)
                .then((response) => {
                    setProjectLogs(response.logs);
                    setTotalItems(response.totalLogs);
                    setIsLoading(false);
                })
                .catch((error) => {
                    setIsLoading(false);
                    openPopup(<ErrorPopup>l
                        <div>{error.message}</div>
                    </ErrorPopup>);
                });
        }
    }, [componentFilter.value?.key, sessionIdFilterFormControl.value, correlationIdFilterFormControl.value, logLevelFilter.value, initialDateFilterFormControl.value, endDateFilterFormControl.value, actorFilterFormControl.value, operationNameFilterFormControl.value, currentPage, itemsPerPage, projectId, openPopup, setIsLoading, setProjectLogs]);

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/
    useEffect(() => {

        getProjectLogs();

        // TO DO: What if there are more than 50 components
        const request = {
            page: 0,
            pageLength: 50,
            column: 'Name',
            isDescending: false,
        }
        componentsService.getComponents(request, projectId + "")
            .then((response) => {
                var mappedComponents = mapComponentDtoToComponentSelectOptions(response.records);
                setComponents(mappedComponents);
            })
            .catch((error) => {
                openPopup(
                    <ErrorPopup>
                        <div>{error.message}</div>
                    </ErrorPopup>
                );
            });

    }, [projectId, currentPage, itemsPerPage])

    useEffect(() => {
        var component = components.find(component => component.key === componentFilter.value?.key)
        if (component) {
            componentFilter.setValue(component);
        }
    }, [components])


    const paginationOptions = useMemo(() => ({
        itemsPerPage: itemsPerPage,
        itemsPerPageOptions: [10, 20, 50],
        onItemsPerPageChanged: setItemsPerPage,
    }), [setItemsPerPage, itemsPerPage])

    /****************************
     * USER ACTIONS
     *****************************/
    const handleClickSearchLogs = useCallback(() => {
        getProjectLogs();
    }, [getProjectLogs]);

    const handleLogClicked = useCallback((log: LogDTO, eventButtonId?: number) => {
        switch (eventButtonId) {
            case 0: //right click
                navigate(log.id || "");
                break;
            case 1: //middle mouse
                window.open(`${window.location.origin}${window.location.pathname}${log.id || ""}`, '_blank');
                break;
        }
    }, [navigate]);


    /****************************
     * FILTER METRICS
     *****************************/
    useEffect(() => {
        if (componentFilter.value) {
            MetricService.getInstance().metric(`SEARCH_FILTER_SELECTED`, `{ "page":"logs", "filter":"component" }`, componentFilter.value?.name ?? null)
        }
    }, [componentFilter.value])
    useEffect(() => {
        if (logLevelFilter.value) {
            MetricService.getInstance().metric(`SEARCH_FILTER_SELECTED`, `{ "page":"logs", "filter":"logLevel" }`, logLevelFilter.value.toString() ?? null)
        }
    }, [logLevelFilter.value])
    useEffect(() => {
        if (initialDateFilterFormControl.value) {
            MetricService.getInstance().metric(`SEARCH_FILTER_SELECTED`, `{ "page":"logs", "filter":"initialDate" }`, initialDateFilterFormControl.value?.setLocale('en-gb').toLocaleString().toString() ?? null)
        }
    }, [initialDateFilterFormControl.value])
    useEffect(() => {
        if (endDateFilterFormControl.value) {
            MetricService.getInstance().metric(`SEARCH_FILTER_SELECTED`, `{ "page":"logs", "filter":"endDate" }`, endDateFilterFormControl.value?.setLocale('en-gb').toLocaleString().toString() ?? null)
        }
    }, [endDateFilterFormControl.value])
    useEffect(() => {
        if (sessionIdFilterFormControl.value) {
            MetricService.getInstance().metric(`SEARCH_FILTER_SELECTED`, `{ "page":"logs", "filter":"sessionId" }`, sessionIdFilterFormControl.value ?? null)
        }
    }, [sessionIdFilterFormControl.value])
    useEffect(() => {
        if (correlationIdFilterFormControl.value) {
            MetricService.getInstance().metric(`SEARCH_FILTER_SELECTED`, `{ "page":"logs", "filter":"correlationId" }`, correlationIdFilterFormControl.value ?? null)
        }
    }, [correlationIdFilterFormControl.value])
    useEffect(() => {
        if (actorFilterFormControl.value) {
            MetricService.getInstance().metric(`SEARCH_FILTER_SELECTED`, `{ "page":"logs", "filter":"user" }`, actorFilterFormControl.value ?? null)
        }
    }, [actorFilterFormControl.value])
    useEffect(() => {
        if (operationNameFilterFormControl.value) {
            MetricService.getInstance().metric(`SEARCH_FILTER_SELECTED`, `{ "page":"logs", "filter":"operationName" }`, operationNameFilterFormControl.value ?? null)
        }
    }, [operationNameFilterFormControl.value])


    /****************************
     * CSS & HTML
     *****************************/

    const rowDefinition = useMemo((): RowDefinition<LogDTO> => ({
        onClick: handleLogClicked
    }), [handleLogClicked]);

    return (
        <PageContainer className="logs-page">

            <Spacer mode="vertical" px="28.5" />
            <h1>Logs</h1>
            <Spacer mode="vertical" px="23" />

            <FlexLayout direction='horizontal' className="filters" verticalAlign="end" >
                <FormFieldSelectSingle
                    label={"Component"}
                    options={components}
                    labelSelector={componentLabelSelector}
                    idSelector={componentIdSelector}
                    placeholder={"Select a component"}
                    formControl={componentFilter}
                    maxHeightOptions={"10rem"}
                />
                <FormFieldSelectMultiple
                    label={"Level"}
                    options={logLevelOptions}
                    labelSelector={logLevelLabelSelector}
                    idSelector={logLevelIdSelector}
                    placeholder={"Select a level"}
                    formControl={logLevelFilter}
                    maxHeightOptions={"10rem"}
                />
                <FormFieldDatePicker
                    label="Initial Date"
                    formControl={initialDateFilterFormControl}
                    placeholder="Select a date"
                    maxDate={endDateFilterFormControl.value ? endDateFilterFormControl.value : DateTime.now()}
                />
                <FormFieldDatePicker
                    label="End Date"
                    formControl={endDateFilterFormControl}
                    placeholder="Select a date"
                    minDate={initialDateFilterFormControl.value ? initialDateFilterFormControl.value : undefined}
                    maxDate={DateTime.now()}
                />
                {/* Fix css */}
                {/* <FormFieldTextInput label="Correlation ID" formControl={correlationIdFilterFormControl} placeholder="Insert the correlation ID" />
                <FormFieldTextInput label="Session ID" formControl={sessionIdFilterFormControl} placeholder="Insert the session ID" />
                <FormFieldTextInput label="User" formControl={actorFilterFormControl} placeholder="Insert the user email" />
                <FormFieldTextInput label="Operation" formControl={operationNameFilterFormControl} placeholder="Insert the operation name" /> */}

            </FlexLayout>
            <FlexLayout direction='horizontal' className="filters" verticalAlign="end" >
                <FormFieldTextInput label="Correlation ID" formControl={correlationIdFilterFormControl} placeholder="Insert the correlation ID" />
                <FormFieldTextInput label="Session ID" formControl={sessionIdFilterFormControl} placeholder="Insert the session ID" />
                <FormFieldTextInput label="User" formControl={actorFilterFormControl} placeholder="Insert the user email" />
                <FormFieldTextInput label="Operation" formControl={operationNameFilterFormControl} placeholder="Insert the operation name" />
            </FlexLayout>

            <div className="button-container">
                <Button className="search-button" text="Search" type="secondary" onClick={() => handleClickSearchLogs()} />
            </div>
            <Spacer mode="vertical" px="12" />

            <Table
                className="logs-table"
                items={projectLogs || []}
                columnDefinitions={projectLogsColumns}
                totalItems={totalItems}
                isLoading={isLoading}
                rowDefinition={rowDefinition}
                paginationOptions={paginationOptions}
                currentPage={currentPage}
                onCurrentPageChanged={setCurrentPage}
            ></Table>

        </PageContainer>
    );
}
