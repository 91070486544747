import "./SelectMultiple.css";

import { isNull, isNullOrEmpty } from "../../../validators/ValidationChecks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ReactComponent as ArrowDownSVG } from "../../../assets/icons/arrowDown.svg";
import { CssClassNameBuilder } from "../../../utils/CssClassNameBuilder";
import React from "react";
import { ReactComponent as RemoveOptionTagSVG } from "../../../assets/icons/close-icon.svg";
import { ReactComponent as SelectedOptionSVG } from "../../../assets/icons/check.svg";
import { TextButton } from "../../buttons/TextButton";
import fuzzysearch from "fuzzysearch-ts";
import removeAccents from "remove-accents";
import { translate } from "../../../infrastructure/i18n/InternationalizationService";
import { useOutsideComponentClickTrigger } from "../../../hooks/useOutsideComponentClickTrigger";

interface ISelectMultipleProps<T> {
  value?: T[]; // TODO: Hadle Initial Value
  options: T[];
  labelSelector: (value: T) => string;
  idSelector: (value: T) => string | number;
  className?: string;
  placeholder?: string;
  isDisabled?: boolean;
  hasError?: boolean;
  maxHeightOptions?: string;
  onChange?: (values?: T[]) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  icon?: React.ReactNode;
  alternativeStyle?: boolean;
}

interface ISelectedOption<T> {
  value?: T;
  id: string | number;
  label: string;
  normalizedLabel: string;
}

export function SelectMultiple<T>(props: ISelectMultipleProps<T>) {
  const nativeInputRef = useRef<HTMLInputElement>(null);
  const componentRef = useRef<HTMLDivElement>(null);

  const [inputValue, setInputValue] = useState<string>();
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<ISelectedOption<T>[]>(
    []
  );
  const [mode, setMode] = useState<"value" | "search">("value");
  const [highlightedOption, setHighlightedOption] = useState<number>(-1);

  /****************************
   * DATA MANIPULATION EFFECTS
   *****************************/

  const placeHolder = useMemo(
    () =>
      !isNull(props.placeholder)
        ? props.placeholder
        : translate("COMMON.FORM.SelectDefaultPlaceholder"),
    [props.placeholder]
  );

  const options = useMemo(() => {
    let mappedOptions = props.options.map((opt): ISelectedOption<T> => {
      const label = props.labelSelector(opt) || "**EMPTY VALUE**";
      const id = props.idSelector(opt);

      if (isNullOrEmpty(id)) {
        console.error("********* There are options without ID!! *********");
      }

      return {
        value: opt,
        label: label,
        id: id,
        normalizedLabel: removeAccents(label.toLowerCase()),
      };
    });
    return mappedOptions;
  }, [props.options, props.idSelector, props.labelSelector]);

  const filteredOptions = useMemo(() => {
    let target = removeAccents(inputValue?.toLowerCase() || "");
    return target
      ? options.filter((opt) => fuzzysearch(target, opt.normalizedLabel))
      : options;
  }, [options, inputValue]);

  useEffect(() => {
    setHighlightedOption(-1);
    if (mode === "search") {
      nativeInputRef.current?.focus();
      setInputValue("");
      setIsPanelOpen(true);
    } else {
      setIsPanelOpen(false);
    }
  }, [mode]);

  useEffect(() => {
    if (props.value) {
      setSelectedOptions(
        props.value.map((value) => {
          return {
            value: value,
            id: props.idSelector(value),
            label: props.labelSelector(value),
            normalizedLabel: removeAccents(
              props.labelSelector(value).toLowerCase()
            ),
          };
        })
      );
    }
  }, [options, props.value]);

  /****************************
   * USER ACTIONS
   *****************************/

  const handleSelectSingleClicked = useCallback(() => {
    if (!props.isDisabled) setMode("search");
  }, [setMode, props.isDisabled]);

  const handleItemSelected = useCallback(
    (
      ev: React.MouseEvent<HTMLDivElement, MouseEvent> | null,
      option: ISelectedOption<T>
    ) => {
      ev?.stopPropagation();

      let valuesArray: ISelectedOption<T>[] = [];

      if (!selectedOptions.some((opt) => opt.id === option.id)) {
        valuesArray = [
          ...selectedOptions,
          {
            value: option.value,
            id: option.id,
            label: option.label,
            normalizedLabel: option.normalizedLabel,
          },
        ];
      } else {
        valuesArray = selectedOptions.filter((opt) => opt.id !== option.id);
      }

      setSelectedOptions(valuesArray);

      props.onChange &&
        props.onChange(
          valuesArray.map((value) => {
            return value.value as T;
          })
        );
    },
    [selectedOptions, setSelectedOptions, props.onChange]
  );

  const handleRemoveSelectedOption = useCallback(
    (
      ev: React.MouseEvent<Element, MouseEvent> | null,
      optionId: string | number
    ) => {
      ev?.stopPropagation();

      var filteredOptions: ISelectedOption<T>[] = selectedOptions.filter(
        (opt) => opt.id !== optionId
      );

      setSelectedOptions(filteredOptions);

      props.onChange &&
        props.onChange(
          filteredOptions.map((value) => {
            return value.value as T;
          })
        );
    },
    [selectedOptions, setSelectedOptions]
  );

  const handleSelectAllClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev?.stopPropagation();

      let valuesArray: T[] = [];
      options.forEach((opt) => {
        if (opt.value) valuesArray.push(opt.value);
      });

      setSelectedOptions([...options]);

      props.onChange && props.onChange(valuesArray);
    },
    [props.onChange, setSelectedOptions, options]
  );

  const handleClearSelectionClicked = useCallback(
    (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      ev?.stopPropagation();

      setSelectedOptions([]);

      props.onChange && props.onChange(undefined);
    },
    [props.onChange, setSelectedOptions]
  );

  const handleKeyDown = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.defaultPrevented) return;

      switch (ev.key) {
        case "Escape":
        case "Tab":
          setMode("value");
          nativeInputRef.current?.blur();
          break;

        case "ArrowDown":
          if (highlightedOption < filteredOptions.length - 1)
            setHighlightedOption(highlightedOption + 1);
          break;

        case "ArrowUp":
          if (highlightedOption > 0)
            setHighlightedOption(highlightedOption - 1);
          break;

        case "Enter":
          if (highlightedOption >= 0) {
            let option = filteredOptions.at(highlightedOption);
            if (option) handleItemSelected(null, option);
            setMode("value");
            nativeInputRef.current?.blur();
          }
          break;
      }
    },
    [
      setMode,
      highlightedOption,
      setHighlightedOption,
      filteredOptions,
      handleItemSelected,
      nativeInputRef,
    ]
  );

  useOutsideComponentClickTrigger(componentRef, () => {
    setInputValue("");
    setMode("value");
  });

  /****************************
   * CSS & HTML
   *****************************/

  const selectMultipleCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("select-multiple")
      .addConditional(props.alternativeStyle, "alternative-style")
      .addConditional(props.className, props.className)
      .addConditional(selectedOptions.length, "has-selected-options")
      .addConditional(props.hasError, "error")
      .addConditional(props.isDisabled, "disabled")
      .addConditional(props.icon, "has-icon")
      .build();
  }, [
    props.className,
    props.hasError,
    props.isDisabled,
    props.icon,
    selectedOptions,
    props.alternativeStyle,
  ]);

  const panelStyle = useMemo(() => {
    let panelObject = {};
    if (componentRef.current && selectedOptions.length) {
      let inputHeight = componentRef.current.clientHeight;
      panelObject = {
        ...panelObject,
        top: inputHeight + 6,
      };
    }
    if (props.maxHeightOptions) {
      panelObject = {
        ...panelObject,
        maxHeight: props.maxHeightOptions,
      };
    }

    return panelObject;
  }, [selectedOptions, props.maxHeightOptions, componentRef]);

  const optionsHTML = useMemo(
    () =>
      filteredOptions.map((opt, idx) => {
        const id = opt.id;

        const isSelected = selectedOptions.some((opt) => opt.id === id);

        const css = CssClassNameBuilder.new()
          .add("multiple-select-option")
          .addConditional(isSelected, "selected")
          .addConditional(highlightedOption === idx, "hover")
          .build();

        return (
          <div
            key={id}
            className={css}
            onClick={(ev) => handleItemSelected(ev, opt)}
          >
            <span>{opt.label}</span>

            {isSelected ? <SelectedOptionSVG className="check-option" /> : null}
          </div>
        );
      }),
    [handleItemSelected, filteredOptions, highlightedOption, selectedOptions]
  );

  const selectedOptionsTags = useMemo(() => {
    if (selectedOptions.length) {
      return (
        <div className="select-multiple-selected-options">
          {selectedOptions.map((opt, idx) => {
            return (
              <OptionSelectedTag
                key={idx}
                value={opt.label}
                onRemove={(ev) => handleRemoveSelectedOption(ev, opt.id)}
              />
            );
          })}
        </div>
      );
    } else return null;
  }, [selectedOptions, handleRemoveSelectedOption]);

  return (
    <div
      ref={componentRef}
      className={selectMultipleCss}
      onClick={handleSelectSingleClicked}
    >
      <div className="select-multiple-box">
        {props.icon ? (
          <div className="select-multiple-icon">{props.icon}</div>
        ) : null}
        <input
          ref={nativeInputRef}
          className="native-input"
          placeholder={placeHolder}
          disabled={props.isDisabled}
          type="text"
          value={inputValue}
          onChange={(ev) => setInputValue(ev.target.value)}
          onKeyDown={handleKeyDown}
        ></input>
        <ArrowDownSVG className="arrow-down-icon" />
      </div>
      {selectedOptionsTags}
      {isPanelOpen ? (
        <div className="select-multiple-options-panel" style={panelStyle}>
          <div className="multiple-select-actions">
            <TextButton
                       text={translate("COMMON.All")}
              className="mini-text-button"
              onClick={handleSelectAllClicked}
            />
            <div className="dot-separator"></div>
            <TextButton
              text={translate("COMMON.Clear")}
              className="mini-text-button"
              onClick={handleClearSelectionClicked}
            />
          </div>
          {optionsHTML}
        </div>
      ) : null}
    </div>
  );
}

interface IOptionSelectedTagProps {
  value: string;
  onRemove: (ev: React.MouseEvent<Element>) => void;
}

export function OptionSelectedTag(props: IOptionSelectedTagProps) {
  return (
    <div className="selected-option-tag">
      <RemoveOptionTagSVG
        className="remove-tag-icon"
        onClick={(ev) => props.onRemove(ev)}
      />
      <span>{props.value}</span>
    </div>
  );
}
