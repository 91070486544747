export class ComponentsChangedEvent {
  static fireComponentsChanged() {
    let event = new CustomEvent<void>("components-changed");
    window.dispatchEvent(event);
  }

  static attachHandler(handler: EventListener) {
    window.addEventListener("components-changed", handler);
  }

  static detachHandler(handler: EventListener) {
    window.removeEventListener("components-changed", handler);
  }
}
