export class CssClassNameBuilder {

    classNames : string[] = []


    static new() {
        return new CssClassNameBuilder();
    }

    add(className: string): CssClassNameBuilder{
        if(className) {
            this.classNames.push(className);
        }
        return this;
    }

    addConditional(condition: any, className: string | undefined) : CssClassNameBuilder {
        if(condition && className)
        {
            this.classNames.push(className);
        }
        return this;
    }

    build(): string {
        return this.classNames.join(" ");
    }
}
