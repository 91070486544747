import { Optional, ValidatorFunction } from "../components/form/Form";

import { validate as isGuidValid } from "uuid";

export function isNull(value: any) {
  return value === undefined || value === null;
}

export function requiredValidator<T>(): ValidatorFunction<T> {
  return (value: T | undefined) => {
    if (!value) {
      return "This field is required!";
    }
    return null;
  };
}

export function validateGuid(): ValidatorFunction<Optional<string>> {
  return (value: string | undefined) => {
    if (!isGuidValid(value || "")) {
      return "Insert a valid GUID";
    }
    return null;
  };
}
