import "./TableCell.css";

import { CssClassNameBuilder } from "../../../utils/CssClassNameBuilder";
import { useMemo } from "react";

export interface ITableCellProps {
    className?: string;
    children?: React.ReactNode;
}

export function TableCell(props: ITableCellProps) {

    const tableCellCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("table-cell")
            .addConditional(props.className, props.className)
            .build();
    }, [props.className]);

    return (
        <td><div className={tableCellCss}>{props.children}</div></td>
    )
}