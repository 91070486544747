import { EditSVG, RemoveSVG } from "../../../../lib/assets/icons";
import { useServiceCall, useServiceCallPro } from "../../../../lib/hooks/useServiceCall";

import { CircleArrowSVG } from "../../../../lib/assets/icons";
import { EditProjectPopup } from "./EditProjectPopup";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { Project } from "../../models/domain/project";
import { ProjectService } from "../../services/ProjectService";
import { ProjectsChangedEvent } from "../../utils/ProjectsChangedEvent";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { WarningPopup } from "../../../../lib/components/popup/WarningPopup";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { usePopup } from "../../../../lib/infrastructure/ui/UIServices";

var projectSvc = new ProjectService();

interface ICellProps {
    value: Project
}

export const DetailCell = (props: ICellProps) => {
    const navigate = useNavigate();

    const handleNavigate = (ev: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => {
        ev?.stopPropagation();
        navigate(props.value.id + '/components/');
    };

    return (
        <div className="options-cell" onClick={ev => handleNavigate(ev)}>
            < CircleArrowSVG  />
        </div>
    );
};


export const NameCell = (props: ICellProps) => {
    return (
        <div className="name-cell">
            <div className="label-name">{props.value.name}</div>
            <div className="label-id">{props.value.externalId}</div>
        </div>
    );
};



export const StatsCell = (value: ICellProps) => {
    return (
        <div className="stats-cell">
            <div className="content-errors"><div className="label-errors"></div>{value.value.inactiveComponents}</div>
            <div className="content-success"><div className="label-success"></div>{value.value.activeComponents}</div>
        </div>
    );
};



export const OptionsCell = (props: ICellProps) => {
    const openPopup = usePopup();

    const deleteProjectCall = useServiceCall(projectSvc.deleteProject);

    /****************************
    * DATA REQUESTS
    *****************************/

    const deleteProject = useCallback(() => {
        deleteProjectCall.invoke(props.value.id)
            .then(() => {
                openPopup(
                    <SuccessPopup>Project removed with success!</SuccessPopup>
                );
                ProjectsChangedEvent.fireProjectsChanged();
            })
            .catch((error) => {
                if (error.response.data) {
                    openPopup(
                        <ErrorPopup>{error.response.data}</ErrorPopup>
                    );
                } else {
                    openPopup(
                        <ErrorPopup>{error.message}</ErrorPopup>
                    );
                }
            });
    }, [deleteProjectCall, props.value.id, openPopup]);


    /****************************
    * USER ACTIONS
    *****************************/

    const handleOnDeleteConfirmClicked = useCallback(() => {
        deleteProject();
    }, [deleteProject]);

    const handleEditProjectClicked = useCallback((ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
            <EditProjectPopup
                projectId={props.value.id}
                onCompleted={() => ProjectsChangedEvent.fireProjectsChanged()}
            />)
    }, [openPopup, props.value.id]);


    const handleRemoveIconClicked = useCallback((ev: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        ev.stopPropagation();
        openPopup(
        <WarningPopup onConfirmClicked={handleOnDeleteConfirmClicked}>
            You are removing this project.
        </WarningPopup>
    )}, [handleOnDeleteConfirmClicked]);

    
    /****************************
    * CSS & HTML
    *****************************/

    return (
        <div>
            <div className="options-cell">
                <EditSVG onMouseDown={handleEditProjectClicked} />
                <RemoveSVG onMouseDown={handleRemoveIconClicked} />
            </div>
        </div>
    );
};