import {
  ProjectDTO,
  ProjectGetAllResponse,
  RecordDTO,
} from "../models/api/ProjectGetAllResponseDTO";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CreatingProjectDTO } from "../../../common/models/CreatingProjectDTO";
import { GetLogsRequest } from "../../../common/models/GetLogsRequest";
import { GetLogsResponse } from "../../../common/models/GetLogsResponse";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { IdentityService } from "../../../lib/infrastructure/identity/IdentityService";
import { ProjectsQueryOptions } from "../../../common/models/ProjectsQueryOptions";
import { UrlUtility } from "../../../lib/utils/UrlUtility";
import qs from 'qs';

const Route = (path: string) => {
  return UrlUtility.join(
    ConfigurationProvider.getConfiguration().App.BackendUrl,
    path
  );
};

export class ProjectService {

  constructor(private correlationId?: string) {}

  async getProjects(options: ProjectsQueryOptions): Promise<ProjectGetAllResponse> {
    return HttpClient.sessionRequest<ProjectGetAllResponse>({
      method: "GET",
      url: Route("/api/Projects"),
      params: options,
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  async getCriticalProjects(): Promise<RecordDTO[]> {
    const token = await IdentityService.Global.getAccessToken();
    return HttpClient.sessionRequest<RecordDTO[]>({
      method: "GET",
      url: Route("/api/Projects/Critical"),
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  getById(id: number): Promise<ProjectDTO> {
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/Projects/${id}`),
      correlationId: this.correlationId,
    })
      .then((res) => res.data)
      .catch((error) => {
        throw error.response?.data;
      });
  }

  updateProject(
    projName?: string,
    extId?: string,
    id?: number
  ): Promise<ProjectDTO> {
    var request: CreatingProjectDTO = {
      name: projName,
      externalId: extId,
    };

    return HttpClient.sessionRequest({
      method: "PUT",
      url: Route(`/api/Projects/${id}`),
      data: request,
      correlationId: this.correlationId,
    })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  createProject(body: CreatingProjectDTO): Promise<ProjectDTO> {
    return HttpClient.sessionRequest<ProjectDTO>({
      method: "POST",
      url: Route("/api/Projects"),
      data: body,
    })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error;
      })
  }

  deleteProject(projectId: number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/Projects/${projectId}`),
    })
      .then((_) => {
      })
      .catch((error) => {
        throw error;
      });
  }

  getProjectLogs(projId: number, request: GetLogsRequest): Promise<GetLogsResponse> {
    console.log(request);
    return HttpClient.sessionRequest({
      method: "GET",
      url: Route(`/api/Projects/${projId}/SearchLogs`),
      params: request,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'})
      }
    })
      .then((res) => {
        //TODO: Improve this creating a new DTO. If Backend changes contract, less problems we will have here.
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
}
