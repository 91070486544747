export class JsonStringHelper {

    static FormatString(text: string) {

        try {
            var description = JSON.stringify(JSON.parse(text), null, "\t");
            description = description.replace(/\\n/g, "<br />").replace(/\\r/g, "")
            description = this.unicodeToChar(description || "")
            return description
        } catch (e) {
            return text;
        }

    }

    static unicodeToChar(text: string) {
        return text.replaceAll(/\\u[\dA-F]{4}/g,
            (match) => {
                const hex = match.replace(/\\u/g, '');
                const decimal = parseInt(hex, 16)
                return String.fromCharCode(decimal);;
            });
    }

}