import React, { createContext, useCallback, useContext, useEffect, useState } from "react";

export interface UISContextState {
  showPopup: boolean;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  popupContent: React.ReactNode;
  setPopupContent: React.Dispatch<React.SetStateAction<React.ReactNode>>;

  viewPortWidth: number;
  setWidth: React.Dispatch<React.SetStateAction<number>>;
  viewPortHeight: number;
  setHeight: React.Dispatch<React.SetStateAction<number>>;

  selectedProject: SelectedProject|undefined;
  setSelectedProject: React.Dispatch<React.SetStateAction<SelectedProject|undefined>>
}

interface SelectedProject {
  id: string;
  name: string;
}

export const UISContext = createContext<UISContextState | null>(null);

interface UISContextProps {
  children: React.ReactNode;
}

export function UISContextProvider({ children }: UISContextProps) {

  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState<React.ReactNode>();

  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);


  const [selectedProject, setSelectedProject] = useState<SelectedProject>();

  const value = {

    showPopup,
    setShowPopup,
    popupContent,
    setPopupContent,

    viewPortWidth: width,
    setWidth,
    viewPortHeight: height,
    setHeight,

    selectedProject,
    setSelectedProject

  };

  return <UISContext.Provider value={value}>{children}</UISContext.Provider>;
}

/**************************************
 *  Selected Project
 *************************************/
export function useSelectedProject() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return context.selectedProject;
}

export function useSetSelectedProject()
  : [(SelectedProject|undefined), React.Dispatch<React.SetStateAction<SelectedProject|undefined>>] {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return [context.selectedProject, context.setSelectedProject];
}
/**************************************
 *  Popup
 *************************************/
export function useRenderPopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  return {
    showPopup: context.showPopup,
    popupContent: context.popupContent
  };
}

export function usePopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const openPopup = useCallback((content: React.ReactNode) => {
      context.setPopupContent(content);
      context.setShowPopup(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [context.setShowPopup, context.setPopupContent]
  );

  return openPopup;
}

export function useClosePopup() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  const closePopup = useCallback(() => {
    context.setShowPopup(false);
    context.setPopupContent(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.setShowPopup, context.setPopupContent]);

  return closePopup;
}

/**************************************
 *  Window Resize
 *************************************/
export function useWindowResize() {
  const context = useContext(UISContext);

  if (!context) throw new Error("erro");

  useEffect(() => {
    const handleWindowResize = () => {
      context.setWidth(window.innerWidth);
      context.setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.setWidth, context.setHeight]);

  return context.viewPortWidth;
}
