import "./DetailCorrelatedLogPopup.css";

import { ArrowSVG, CloseSVG } from "../../../../lib/assets/icons";
import { useCallback, useEffect, useMemo, useState, } from "react";

import { CssClassNameBuilder } from "../../../../lib/utils/CssClassNameBuilder";
import { DateTimeHelper } from "../../../../common/utils/DateTimeHelper";
import { InfoBlock } from "../../../../lib/components/info-block/InfoBlock";
import { JsonStringHelper } from "../../../../common/utils/JsonStringHelper";
import { LogDTO } from "../../../../common/models/LogDTO";
import { LogLevel } from "../../../../common/models/LogLevel";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { useClosePopup } from "../../../../lib/infrastructure/ui/UIServices";

interface IProps {
    correlatedLog: LogDTO;
    totalLogs: number;
    currentIndex: number;
    logLevel: string;
    onCurrentLogIndexChanged: (newLogIndex: number) => void;
}



export function DetailCorrelatedLogPopup(props: IProps) {
    const closePopup = useClosePopup();
    const [logDescription, setLogDescription] = useState("No description provided.");


    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    useEffect(() => {
        if (props.correlatedLog.description === undefined ||
            props.correlatedLog.description === "" ||
            props.correlatedLog.description === null) {
            return
        }

        setLogDescription(JsonStringHelper.FormatString(props.correlatedLog.description))
    }, [props.correlatedLog.description, logDescription])

    const hasNextLog = useMemo(() => {
        return props.currentIndex < props.totalLogs - 1;
    }, [props.currentIndex, props.totalLogs]);

    const hasPreviousLog = useMemo(() => {
        return props.currentIndex > 0;
    }, [props.currentIndex]);

    /****************************
     * USER ACTIONS
     *****************************/

    const handleNextLogClicked = useCallback(() => {
        if (hasNextLog) {
            props.onCurrentLogIndexChanged(props.currentIndex + 1);
        }
    }, [hasNextLog, props.onCurrentLogIndexChanged, props.currentIndex]);

    const handlePreviousLogClicked = useCallback(() => {
        if (hasPreviousLog) {
            props.onCurrentLogIndexChanged(props.currentIndex - 1);
        }
    }, [props.onCurrentLogIndexChanged, hasPreviousLog, props.currentIndex]);

    /****************************
     * CSS & HTML
     *****************************/

    const logLevelCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("log-level")
            .addConditional(LogLevel.INFO === props.correlatedLog.level, "primary-color")
            .addConditional(LogLevel.FATAL === props.correlatedLog.level || LogLevel.ERROR === props.correlatedLog.level || LogLevel.CRITICAL_ERROR === props.correlatedLog.level, "red-color")
            .addConditional(LogLevel.DEBUG === props.correlatedLog.level || LogLevel.TRACE === props.correlatedLog.level || LogLevel.UNKNOWN === props.correlatedLog.level, "grey-color")
            .build();
    }, [props.correlatedLog.level]);

    const nextButtonCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("move-log-button")
            .addConditional(!hasNextLog, "disable")
            .build();
    }, [hasNextLog]);

    const previousButtonCss = useMemo(() => {
        return CssClassNameBuilder.new()
            .add("move-log-button")
            .add("rotate")
            .addConditional(!hasPreviousLog, "disable")
            .build();
    }, [hasPreviousLog]);

    return (
        <PopupContainer className="detail-correlated-log-popup">
            <div className="detail-correlated-header">
                <div className="detail-correlated-title">
                    <h1 title={props.correlatedLog.message || ""} >{props.correlatedLog.message}</h1>
                </div>
                <div className={logLevelCss}>
                    <div className="circle"></div>
                    <div className="description">{props.logLevel}</div>
                </div>
                <div className="move-log-options">
                    <div className={previousButtonCss} onClick={handlePreviousLogClicked}><ArrowSVG /></div>
                    <div className={nextButtonCss} onClick={handleNextLogClicked}><ArrowSVG /></div>
                </div>
                <div className="detail-close-popup" onClick={() => closePopup()}> <CloseSVG /> </div>
            </div>
            <div className="detail-correlated-info">
                <div className="detail-correlated-info-main">
                    <div className="detail-correlated-info-main-column">
                        <InfoBlock label="Date" text={DateTimeHelper.formatDateTime(props.correlatedLog.timeStamp)}></InfoBlock>
                        <InfoBlock label="Project ID" text={props.correlatedLog.projectId || ""}></InfoBlock>
                        <InfoBlock label="Component ID" text={props.correlatedLog.componentName || props.correlatedLog.componentId || ""}></InfoBlock>
                    </div>

                    <div className="detail-correlated-info-main-column">
                        <InfoBlock label="Operation Name" text={props.correlatedLog.operationName || "-"}></InfoBlock>
                        <InfoBlock label="Actor" text={props.correlatedLog.actor || "-"}></InfoBlock>
                        <InfoBlock label="Session ID" text={props.correlatedLog.sessionId || "-"}></InfoBlock>
                        <InfoBlock label="Correlation ID" text={props.correlatedLog.correlationId || ""}></InfoBlock>
                    </div>
                </div>
                <Spacer px={25} mode="vertical" />
                <div className="detail-correlated-info-description">
                    <InfoBlock label="Description" text={logDescription || "No description provided"}></InfoBlock>
                </div>
            </div>
        </PopupContainer>
    );
}


