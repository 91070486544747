import { FormField } from "./FormField";
import { IFormControl } from "../Form";
import { TextInput } from "../text-input/TextInput";
import { useMemo } from "react";

interface IFormFieldTextInputProps<T> {
  label?: string;
  formControl: IFormControl<T>
  icon?: React.ReactNode;
  placeholder?: string;
  dataTest?: string;
}



export function FormFieldTextInput<T>(props: IFormFieldTextInputProps<T>) {

  const placeholder = useMemo(() => props.placeholder || "Type here", [props.placeholder]);


  return (
    <FormField label={props.label} formControl={props.formControl}>
      <TextInput
        dataTest={props.dataTest}
        value={props.formControl.value}
        placeholder={placeholder}
        icon={props.icon}
        isDisabled={props.formControl.isDisabled}
        onChange={(val) => props.formControl.setValue(val)}
        hasError={props.formControl.hasErrors}
      ></TextInput>
    </FormField>

  )
}
