import { Component } from "../domain/Component";
import { ComponentHealthcheckDTO } from "../../../common/models/EditComponentHealthcheckDTO";
import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { CreatingComponentDTO } from "../../../common/models/CreatingComponentDTO";
import { EditComponentDTO } from "../../../common/models/EditComponentDTO";
import { HttpClient } from '../../../lib/infrastructure/http/HttpClient';
import { UrlUtility } from "../../../lib/utils/UrlUtility";
import { ComponentGetAllResponseDTO } from "../models/ComponentGetAllResponseDTO";
import { QueryOptions } from "../../../common/models/QueryOptions";


const Route= (path: string) => {
  return UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, path);
}


export class ComponentService {
  async getComponents(options: QueryOptions, id: string): Promise<ComponentGetAllResponseDTO> {
    return HttpClient.sessionRequest<ComponentGetAllResponseDTO>({
        method: "GET",
        url: Route(`/api/Projects/${id}/Components`),
        params: options,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  createComponent(body: CreatingComponentDTO, projId: number): Promise<Component> {
    return HttpClient.sessionRequest<Component>({
        method: "POST",
        url: Route(`/api/Projects/${projId}/Components`),
        data: body,
      })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error;
      })
  }

  deleteComponent(projectId: number, componentId:number): Promise<void> {
    return HttpClient.sessionRequest({
      method: "DELETE",
      url: Route(`/api/Projects/${projectId}/Components/${componentId}`)
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }

  getById(projectId: number, componentId:number): Promise<Component> {
    return HttpClient.sessionRequest<Component>({
        method: "GET",
        url: Route(`/api/Projects/${projectId}/Components/${componentId}`),
      })
      .then((res) => res.data)
      .catch((error) => {
        throw error.response?.data;
      });
  }

  updateComponent(
    compName: string,
    extId: string,
    id: number,
    projId:number
  ): Promise<Component> {
    var request: EditComponentDTO = {
      name: compName,
      externalId: extId,
    };

    return HttpClient.sessionRequest<Component>({
        method: "PUT",
        url: Route(`/api/Projects/${projId}/Components/${id}`),
        data: request,
      })
      .then((res) => {
        return res.data
      })
      .catch((error) => {
        throw error;
      })
  }

  updateComponentHealthCheck(
    url?: string ,
    time?: number,
    id?: number,
    projId?:number
  ): Promise<Component> { //FIXME: WTF IS THIS RETURN TYPE? REVIEW THIS
    var request: ComponentHealthcheckDTO = {
      url: url,
      time: time,
    };

    return HttpClient.sessionRequest({
        method: "PUT",
        url: Route(`/api/Projects/${projId}/Components/${id}/Healthcheck`),
        data: request,
      })
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  getHealthcheckById(projectId: number, componentId:number): Promise<ComponentHealthcheckDTO> {
    return HttpClient.sessionRequest({
        method: "GET",
        url: Route(`/api/Projects/${projectId}/Components/${componentId}/Healthcheck`),
      })
      .then((res) => res.data)
      .catch((error) => {
        throw error.response?.data;
      });
  }

  deleteComponentHealthcheck(projectId: number, componentId:number): Promise<void> {
    return HttpClient.sessionRequest( {
      method:"DELETE",
      url: Route(`/api/Projects/${projectId}/Components/${componentId}/Healthcheck`)
    })
      .then((_) => {})
      .catch((error) => {
        throw error;
      });
  }
}
