import React, { useMemo } from "react";

import { ReactComponent as ArrowDownSVGComponent } from "./icons/arrowDown.svg";
import { ReactComponent as ArrowSVGComponent } from "./icons/arrowRight.svg";
import { ReactComponent as CircleArrowSVGComponent } from "./icons/circle-arrow.svg";
import { ReactComponent as CloseSVGComponent } from "./icons/close.svg";
import { ReactComponent as DiamondSVGComponent } from "./icons/diamond.svg";
import { ReactComponent as EditSVGComponent } from "./icons/edit.svg";
import { ReactComponent as ErrorSVGComponent } from "./icons/error-big.svg";
import { ReactComponent as HeartSVGComponent } from "./icons/heart-icon.svg";
import { ReactComponent as PlusSVGComponent } from "./icons/plus.svg";
import { ReactComponent as RemoveSVGComponent } from "./icons/remove.svg";
import { ReactComponent as SuccessSVGComponent } from "./icons/success-big.svg";
import { ReactComponent as WarningMessageSVGComponent } from "./icons/warning-message.svg";

export const CircleArrowSVG = withWrapper(CircleArrowSVGComponent)
export const RemoveSVG = withWrapper(RemoveSVGComponent);
export const DiamondSVG = withWrapper(DiamondSVGComponent);
export const WarningMessageSVG = withWrapper(WarningMessageSVGComponent);
export const PlusSVG = withWrapper(PlusSVGComponent);
export const ErrorSVG = withWrapper(ErrorSVGComponent);
export const SuccessSVG = withWrapper(SuccessSVGComponent);
export const HeartSVG = withWrapper(HeartSVGComponent);
export const ArrowSVG = withWrapper(ArrowSVGComponent);
export const ArrowDownSVG = withWrapper(ArrowDownSVGComponent);
export const EditSVG = withWrapper(EditSVGComponent);
export const CloseSVG = withWrapper(CloseSVGComponent);
















function withWrapper(Component: any) {
    return (props: React.SVGProps<SVGSVGElement>) => <SVGWrapper svg={Component} {...props} />;
}

interface SVGWrapperProps extends React.SVGProps<SVGSVGElement> {
    svg: any
}

export const SVGWrapper = (props: SVGWrapperProps) => {

    const component = useMemo(() => {
    
        return React.createElement(props.svg, {...props, 
            svg:undefined, 
            onClick: (ev:React.MouseEvent<SVGSVGElement, MouseEvent>) =>{
                if(props.onClick){
                    ev.stopPropagation();
                    props.onClick(ev);
                }
            }
        });
    }, [props])

    return component;
};
