import "./EditComponentPopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../lib/components/buttons/Button";
import { ComponentService } from "../../services/ComponentService";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { requiredValidator } from "../../../../lib/utils/ValidationChecks";
import {
  useFormControl,
} from "../../../../lib/components/form/Form";

interface IProps {
  projectId: number;
  componentId: number;
  onCompleted: () => void;
}

var compService = new ComponentService();

export function EditComponentPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [componentName, setComponentName] = useState<string>("");
  const [externalId, setExternalId] = useState<string>("");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);

  var componentNameFormControl = useFormControl<string>({
    isDisabled: componentName.length === 0,
    validators: [requiredValidator()],
    initialValue: ''
  });

  var externalIdFormControl = useFormControl<string>({
    isDisabled: externalId.length === 0,
    initialValue: externalId || '',
    validators: [requiredValidator()],
  });


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/
  const isButtonDisabled = useMemo(() => {
    return (
      componentName.length === 0 ||
      externalId.length === 0 ||
      isSaveButtonDisabled
    );
  }, [componentName, externalId, isSaveButtonDisabled]);

  useEffect(() => {
    compService
      .getById(props.projectId, props.componentId)
      .then((response) => {
        setComponentName(response.name);
        setExternalId(response.externalId);
        componentNameFormControl.setValue(response.name);
        externalIdFormControl.setValue(response.externalId);
        setIsLoading(false);
      })
      .catch((error) => {
        openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
      });
  }, []);

  useEffect(() => {
    setComponentName(componentNameFormControl.value || "");
    setExternalId(externalIdFormControl.value || "");
  }, [componentNameFormControl, externalIdFormControl]); // ! : .value ???  

  /****************************
  * USER ACTIONS
  *****************************/

  const handleEditComponentClicked = useCallback(
    () => {
      if (componentName && externalId) {

        setIsSaveButtonDisabled(true);
        setIsLoading(true);

        compService
          .updateComponent(componentName, externalId, props.componentId, props.projectId)
          .then(() => {
            setIsLoading(false);
            setIsSaveButtonDisabled(false);
            openPopup(
              <SuccessPopup>
                <div>Component edited with success!</div>
              </SuccessPopup>
            );
            props.onCompleted();
          })
          .catch((error) => {
            setIsSaveButtonDisabled(false);
            openPopup(
              <ErrorPopup>
                <div>{error.response.data.message}</div>
              </ErrorPopup>
            );
          });
      }
    },
    [ setIsSaveButtonDisabled,
      setIsLoading,
      props.componentId,
      props.projectId,
      openPopup,
      componentName,
      externalId]
  );



  /****************************
  * CSS & HTML
  *****************************/



  return (
    <PopupContainer className="edit-popup">
      {isLoading ? (
        <Loader />
      ) : (
        <>

          <FormContainer className="inputs">
            <FormFieldTextInput
              formControl={componentNameFormControl}
              label="Component Name"
            />
            <FormFieldTextInput
              formControl={externalIdFormControl}
              label="External ID"
            />
          </FormContainer>

          <LineSeparator />

          <div className="act-btns">
            <TextButton
              type="white"
              text={"Cancel"}
              onClick={() => closePopup()}
            />
            <Button
              text="Edit"
              type="primary"
              isDisabled={isButtonDisabled}
              onClick={handleEditComponentClicked}
            />
          </div>

        </>
      )}
    </PopupContainer>
  );
}
