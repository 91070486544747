import "./DetailedCard.css";

import { CardContainer } from "../../layouts/containers/card/CardContainer";
import { ReactComponent as CircleArrowSVG } from "../../assets/icons/circle-arrow.svg";
import { CssClassNameBuilder } from "../../utils/CssClassNameBuilder";
import { useMemo } from "react";

interface IDetailedCardProps {
  className?: string;
  title: string;
  hideAvatar?: boolean;
  onClick?: () => void;
  description?: string;
  footerLabel?: string;
  children?: React.ReactNode;
}

export function DetailedCard(props: IDetailedCardProps) {
  const cardCss = useMemo(() => {
    return CssClassNameBuilder.new()
      .add("detailed-card")
      .addConditional(props.className, props.className)
      .build();
  }, [props.className]);

  const detailedCardAvatar = useMemo(() => {
    if (!props.hideAvatar)
      return (
        <div className="detailed-card-avatar">
          <h1>{props.title.charAt(0)}</h1>
        </div>
      );
    else return null;
  }, [props.hideAvatar, props.title]);

  const detailedCardDescription = useMemo(() => {
    if (props.description)
      return (
        <div className="detailed-card-description">{props.description}</div>
      );
    else return null;
  }, [props.description]);

  const detailedCardFooterLabel = useMemo(() => {
    if (props.footerLabel)
      return (
        <div className="caption detailed-card-footer-label">
          {props.footerLabel}
        </div>
      );
    else return <div></div>;
  }, [props.footerLabel]);

  return (
    <CardContainer className={cardCss}>
      <div className="detailed-card-info">
        <div className="detailed-card-header">
          {detailedCardAvatar}
          <h3>{props.title}</h3>
        </div>
        {detailedCardDescription}
        {props.children && props.children}
      </div>
      <div className="detailed-card-footer">
        {detailedCardFooterLabel}
        <div className="detailed-card-button" onClick={props.onClick}>
          <CircleArrowSVG />
        </div>
      </div>
    </CardContainer>
  );
}
