
export interface Component {
    id: number;
    externalId: string;
    name: string;
    parentProject: number;
    status: ComponentStatus;
}

export enum ComponentStatus {
    NONE = 0,
    OK = 1,
    NOK = 2
}