import "./AddComponentPopup.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../lib/components/buttons/Button";
import { ComponentService } from "../../services/ComponentService";
import { CreatingComponentDTO } from "../../../../common/models/CreatingComponentDTO";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FlexLayout } from "../../../../lib/layouts/containers/flex/FlexLayout";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { requiredValidator } from "../../../../lib/utils/ValidationChecks";
import { useFormControl } from "../../../../lib/components/form/Form";

interface IProps {
  projId?: string;
  onCompleted: () => void;
}


var compService = new ComponentService();

export function AddComponentPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const [projectName, setProjectName] = useState<string>("");
  const [projExternalId, setProjExternalId] = useState("");

  const projectNameFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    initialValue: ''
  });
  const projExternalIdFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    enableAutoValidate: true,
    initialValue: ''
  });


  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  const isAddButtonDisabled = useMemo(()=> projectName.length === 0,[projectName])

  useEffect(() => {
    setProjectName(projectNameFormControl.value || "");
    setProjExternalId(projExternalIdFormControl.value || "");
  }, [projectNameFormControl, projExternalIdFormControl]);


  /****************************
  * USER ACTIONS
  *****************************/

  const handleAddComponentClicked = useCallback(() => {

    var requestBody: CreatingComponentDTO = {
      name: projectName,
      externalId: projExternalId,
    };

    if (props.projId) {
      compService
        .createComponent(requestBody, parseInt(props.projId))
        .then((response) => {
          openPopup(
            <SuccessPopup
              children={"New external ID: " + response.externalId}
            ></SuccessPopup>
          );
          props.onCompleted();
        })
        .catch((err) => {
          openPopup(<ErrorPopup>{err.response.data.message}</ErrorPopup>);
        });
    }
  },
    [projectName, setProjectName, projExternalId, setProjExternalId, props.projId, openPopup, props.onCompleted]
  );

  const handleCancelClicked = useCallback(() => closePopup(),[closePopup]);

  /****************************
  * CSS & HTML
  *****************************/

  return (
    <PopupContainer className="add-component-popup">
      <h3>Add component</h3>
      <Spacer mode="vertical" px="16" />

      <FormContainer className="inputs">
        <FormFieldTextInput
          formControl={projectNameFormControl}
          label="Component Name"
        />
        <FormFieldTextInput
          formControl={projExternalIdFormControl}
          label="Component External ID"
        />
      </FormContainer>

      <Spacer mode="vertical" px="16" />
      <LineSeparator />

      <FlexLayout
        className="act-btns"
        direction="horizontal"
        horizontalAlign="end"
        verticalAlign="center"
      >
        <TextButton type="white" text={"Cancel"} onClick={handleCancelClicked} />
        <Button
          text="Add"
          type="primary"
          isDisabled={isAddButtonDisabled}
          onClick={handleAddComponentClicked}
        />
      </FlexLayout>
    </PopupContainer>
  );
}
