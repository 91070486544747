import "./EditComponentHealthPopup.css";

import { isNull, requiredValidator } from "../../../../lib/utils/ValidationChecks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../lib/components/buttons/Button";
import { ComponentService } from "../../services/ComponentService";
import { ComponentsChangedEvent } from "../../utils/ComponentsChangedEvent";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import isUrl from 'is-url';
import { useFormControl } from "../../../../lib/components/form/Form";

interface IProps {
  projectId: number;
  componentId: number;
  onCompleted: () => void;
}

var compService = new ComponentService();

export function EditComponentHealthPopup(props: IProps) {
  const closePopup = useClosePopup();
  const openPopup = usePopup();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] =
    useState<boolean>(false);

  var urlFormControl = useFormControl<string>({
    validators: [requiredValidator()],
    initialValue: ''
  });
  var timeFormControl = useFormControl<number>({
    initialValue: 0,
    validators: [requiredValidator()],
  });



  /****************************
  * DATA MANIPULATION EFFECTS
  *****************************/

  const isButtonDisabled = useMemo(() => {
    console.log("urlFormControl.value", urlFormControl.value)
    if(urlFormControl.value){
      return urlFormControl.value.length === 0 || isSaveButtonDisabled || !isUrl(urlFormControl.value);
    }

    return true
    
  }, [urlFormControl, isSaveButtonDisabled]);

  useEffect(() => {
    compService
      .getHealthcheckById(props.projectId, props.componentId)
      .then((response) => {
        urlFormControl.setValue(response.url || "");
        timeFormControl.setValue(response.time);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        //openPopup(<ErrorPopup><div>{error.message}</div></ErrorPopup>);
        //TO DO: falta condição para verificar se o erro é 404 (pending backend)

        //openPopup(<ErrorPopup children={"Não foi possivel carregar a informação"} />);
      });
  }, []);


  /****************************
  * USER ACTIONS
  *****************************/

  const handleDeleteComponentClicked = useCallback(
    () => {
      if (urlFormControl.isValid && timeFormControl.isValid) {
        compService
          .deleteComponentHealthcheck(props.projectId, props.componentId)
          .then(() => {
            openPopup(<SuccessPopup children={"Component Healthcheck deleted!"} />);
            ComponentsChangedEvent.fireComponentsChanged();
          })
          .catch((error) => {
            openPopup(
              <ErrorPopup>
                <div>{error.response.data.message}</div>
              </ErrorPopup>
            );
          });
      }
    },
    [props.projectId, props.componentId, openPopup, urlFormControl, timeFormControl]
  );

  const handleEditComponentClicked = useCallback(
    () => {
      if (urlFormControl.isValid && timeFormControl.isValid) {
        setIsSaveButtonDisabled(true);
        setIsLoading(true);


        compService
          .updateComponentHealthCheck(
            urlFormControl.value,
            timeFormControl.value,
            props.componentId,
            props.projectId
          )
          .then(() => {
            setIsLoading(false);
            setIsSaveButtonDisabled(false);
            openPopup(
              <SuccessPopup>
                <div>Component Healthcheck edited with success!</div>
              </SuccessPopup>
            );
            props.onCompleted();
          })
          .catch((error) => {
            setIsSaveButtonDisabled(false);
            openPopup(
              <ErrorPopup>
                <div>{error.response.data.message}</div>
              </ErrorPopup>
            );
          });
      }
    },
    [urlFormControl, timeFormControl, setIsSaveButtonDisabled, setIsLoading, props.componentId, props.projectId, openPopup]
  );

  const handleCancelClicked = useCallback(() => {
    closePopup()
  }, [closePopup])


  /****************************
  * CSS & HTML
  *****************************/


  if (isLoading) return (
    <PopupContainer className="edit-popup">
      <Loader />
    </PopupContainer>)


  return (
    <PopupContainer className="edit-popup">
      <FormContainer className="inputs">
        <FormFieldTextInput
          formControl={urlFormControl}
          label="Healthcheck URL"
        />
        <FormFieldTextInput
          formControl={timeFormControl}
          label="Time (seconds)"
        />
      </FormContainer>

      <LineSeparator />

      <div className="area-btns">
        <div className="del-btn">
          <Button
            text="Delete"
            type="secondary"
            isDisabled={isButtonDisabled}
            onClick={handleDeleteComponentClicked}
          />
        </div>
        <div className="act-btns">
          <TextButton
            type="white"
            text={"Cancel"}
            onClick={handleCancelClicked}
          />
          <Button
            text="Edit"
            type="primary"
            isDisabled={isButtonDisabled}
            onClick={handleEditComponentClicked}
          />
        </div>
      </div>
    </PopupContainer>
  );
}
