import { Component } from "../../domain/Component";

interface IComponentNameCellProps {
    value: Component
}

export const ComponentNameCell = (props: IComponentNameCellProps) => {
    return (
        <div className="name-cell">
            <div className="label-name">{props.value.name}</div>
            <div className="label-id">{props.value.externalId}</div>
        </div>
    );
};