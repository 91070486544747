import { useEffect, useState } from "react";
import "./Loader.css";

interface ILoaderProps {}

export function Loader(props: ILoaderProps) {

  return (
    <div className="loader-container">
      <div className="loader">
      </div>
      <div className="loader">
      </div>
      <div className="loader">
      </div>
      <div className="loader">
      </div>
      <div className="loader">
      </div>
      <div className="loader">
      </div>
    </div>
  );
}
