import { Component } from "../components/domain/Component";
import { ComponentSelectOption } from "../../common/models/common/ComponentSelectOption";

export function mapComponentDtoToComponentSelectOptions(components: Component[]) {
    var optionsMapped = components.map((obj) => {
        var opMapped: ComponentSelectOption = {
            name: obj.name,
            key: obj.externalId,
        };
        return opMapped;
    });
    return optionsMapped;
}

