import "./NavBarAppMenu.css";

import { Button } from "../../buttons/Button";
import { TextButton } from '../../buttons/TextButton';
import { useNavigate } from 'react-router-dom';

interface INavBarAppMenuProps {
    onClose: () => any;
}
export function NavBarAppMenu(props: INavBarAppMenuProps) {

    const navigate = useNavigate();


    return (
        <div className="nav-bar-app-menu">
            <TextButton text="Ark" className="nav-bar-btn" type="white" onClick={() => {props.onClose(); window.open("https://ark.orbitalwatch.dev") }} />
            <TextButton text="Aurora"  className="nav-bar-btn" type="white" onClick={() => {props.onClose();  window.open("https://aurora.orbitalwatch.dev")}} />
            <TextButton text="Galileo"  className="nav-bar-btn" type="white" onClick={() => {props.onClose(); window.open("https://galileo.orbitalwatch.dev")}} />
            <TextButton text="Universe" className="nav-bar-btn"  type="white" onClick={() => {props.onClose(); window.open("https://universe.orbitalwatch.dev")}} />

            <TextButton text="Devops" className="nav-bar-btn"  type="white" onClick={() => {props.onClose(); window.open("https://dev.azure.com/n4it-Enterprise-Solutions/")}} />
        </div>
    )
}