import React, { useEffect } from "react";

export function useEvent(eventName: string, eventFunc: (e?:any) => void | any,  deps?: React.DependencyList | undefined) {
    useEffect(() => {

      console.debug("mount event", eventName);
      window.addEventListener(eventName, eventFunc);
      return () => {
        console.debug("unmount event", eventName);
        window.removeEventListener(eventName, eventFunc);
      }
    }, deps || []);
}
