import { ComponentStatus } from "../../domain/Component";

import { useMemo } from "react";

interface IStatusCellProps {
    status: ComponentStatus
}


export const ComponentStatusCell = (props: IStatusCellProps) => {

    /****************************
     * CSS & HTML
     *****************************/

    const respectiveCircleHTML = useMemo(() => {
        switch (props.status) {
            case ComponentStatus.OK: {
                return (
                    <div className="content-success">
                        <div className="label-success"></div>
                    </div>
                );
            }

            case ComponentStatus.NOK: {
                return (
                    <div className="content-errors">
                        <div className="label-errors"></div>
                    </div>
                );
            }

            default: {
                return (
                    <div className="content-none">
                        <div className="label-none"></div>
                    </div>
                );
            }
        }

    }, [props.status]);

    return (
        <div className="stats-cell">
            {respectiveCircleHTML}
        </div>
    );
};