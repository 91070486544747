import { IdentityApp } from '../identity/components/IdentityApp';
import { UISContextProvider } from "../ui/UIServices";

interface ISPAAppProps {
  children: React.ReactNode;
}

export function SPAApp(props: ISPAAppProps) {
  return (

    <IdentityApp>
      <UISContextProvider>
        {props.children}
      </UISContextProvider>
    </IdentityApp>

  );
}
