import { ErrorSVG, SuccessSVG } from "../../../../lib/assets/icons";

import { DetailedCard } from "../../../../lib/components/cards/DetailedCard";
import { Project } from "../../models/domain/project";
import { TempCardProperties } from "./ProjectsHomePage";
import { useNavigate } from "react-router-dom";

interface ICriticalProjectCardProps {
  idx: number,
  project: Project
}


export const CriticalProjectCard = (props: ICriticalProjectCardProps) => {
  const navigate = useNavigate();

  return (
    <DetailedCard key={props.idx} title={props.project.name} footerLabel={props.project.failuresInLast24h + " fails / last 24h"}
      onClick={() => {
        return props.project?.id ? navigate(props.project?.id + "/") : null
      }} >
      <div className="detailed-card-status">
        <div className="errors">
          <ErrorSVG className="icon" />
          <div className="total-errors">{props.project.inactiveComponents}</div>
        </div>
        <div className="success">
          <SuccessSVG className="icon" />
          <div className="total-success">{props.project.activeComponents}</div>
        </div>
      </div>
    </DetailedCard>
  );
};
