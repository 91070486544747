import { ConfigurationProvider } from '../configuration/ConfigurationProvider';
import { IdentityProvider } from '../identity/IdentityProvider';
import { RealtimeProvider } from '../../../features/logs/services/RealtimeProvider';
import axios from "axios";
import { setupInterceptorsTo } from '../http/Interceptors';

export class Startup {
    static async Run( app: () => void) {

        await ConfigurationProvider.init();
        setupInterceptorsTo(axios)

        await Promise.all([
            IdentityProvider.init(),
            RealtimeProvider.init()
        ])

        app();
    }
}
