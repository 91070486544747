import axios, { AxiosResponse } from "axios";

import { ConfigurationProvider } from "../../../lib/infrastructure/configuration/ConfigurationProvider";
import { HttpClient } from "../../../lib/infrastructure/http/HttpClient";
import { LogDTO } from "../../../common/models/LogDTO";
import { UrlUtility } from "../../../lib/utils/UrlUtility";

const Route= (path: string) => {
  return UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, path);
}


function DefaultReturnHandler<T>(res: AxiosResponse<T>) {
  return res.data;
}

function DefaultErrorHandler(err: any) : never {
  console.log(err);
  throw err;
}

export class LogService {


  getLogDetails(prjId: string, logId: string): Promise<LogDTO> {
    return HttpClient.sessionRequest<LogDTO>({
        method: "GET",
        url: Route(`/api/Projects/${prjId}/Logs/${logId}`),
      })
      .then(DefaultReturnHandler)
      .catch(DefaultErrorHandler);
  }

  getLogsCorrelated(prjId: string, logId: string): Promise<LogDTO[]> {
    return HttpClient.sessionRequest<LogDTO[]>({
        method: "GET",
        url: Route(`/api/Projects/${prjId}/Logs/${logId}/Correlated`),
      })
      .then(DefaultReturnHandler)
      .catch(DefaultErrorHandler);
  }
}


export const  LogServiceInstance = new LogService();