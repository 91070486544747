export class ProjectsChangedEvent {
 static fireProjectsChanged() {
    let event = new CustomEvent<void>("projects-changed");
    window.dispatchEvent(event);
 }

 static attachHandler(handler: EventListener){
    window.addEventListener("projects-changed", handler);
 }

 static detachHandler(handler: EventListener){
    window.removeEventListener("projects-changed", handler);
 }
}