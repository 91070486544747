import "./EditProjectPopup.css";

import { requiredValidator, validateGuid } from "../../../../lib/utils/ValidationChecks";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useClosePopup, usePopup } from "../../../../lib/infrastructure/ui/UIServices";

import { Button } from "../../../../lib/components/buttons/Button";
import { ErrorPopup } from "../../../../lib/components/popup/ErrorPopup";
import { FormContainer } from "../../../../lib/layouts/containers/form/FormContainer";
import { FormFieldTextInput } from "../../../../lib/components/form/form-field/FormFieldTextInput";
import { LineSeparator } from "../../../../lib/components/separator/LineSeparator";
import { Loader } from "../../../../lib/components/loader/Loader";
import { PopupContainer } from "../../../../lib/layouts/containers/popup-container/PopupContainer";
import { ProjectService } from "../../services/ProjectService";
import { Spacer } from "../../../../lib/components/separator/Spacer";
import { SuccessPopup } from "../../../../lib/components/popup/SuccessPopup";
import { TextButton } from "../../../../lib/components/buttons/TextButton";
import { useFormControl } from "../../../../lib/components/form/Form";
import {v4 as uuidv4} from 'uuid';

interface IProps {
    projectId: number;
    onCompleted: () => void;
}


export function EditProjectPopup(props: IProps) {

    const closePopup = useClosePopup();
    const openPopup = usePopup();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const prjService = useRef(new ProjectService(uuidv4()));

    var projectNameFormControl = useFormControl<string>({ validators: [requiredValidator()], initialValue: '' });
    var externalIdFormControl = useFormControl<string>({ validators: [validateGuid()], initialValue: '' })
    /****************************
     * DATA REQUESTS
     *****************************/

    /****************************
     * DATA MANIPULATION EFFECTS
     *****************************/

    const isFormValid = useMemo(() => projectNameFormControl.isValid && externalIdFormControl.isValid
     && projectNameFormControl.value && externalIdFormControl.value, [projectNameFormControl, externalIdFormControl])

    const isButtonDisabled = useMemo(() => {
        return !isFormValid || isLoading;
    }, [isFormValid, isLoading]);

    useEffect(() => {
        prjService.current.getById(props.projectId).then((response) => {
            projectNameFormControl.setValue(response.name);
            externalIdFormControl.setValue(response.externalId);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            openPopup(<ErrorPopup>{error.message}</ErrorPopup>);
        });

    }, []);

    /****************************
     * USER ACTIONS
     *****************************/

    const handleOnClickEditProject = useCallback(() => {

        if (!isFormValid) return;

        setIsLoading(true);
        prjService.current.updateProject(projectNameFormControl.value, externalIdFormControl.value, props.projectId).then((response) => {
            setIsLoading(false);
            openPopup(<SuccessPopup>
                <div>Project edited with success!</div>
            </SuccessPopup>);
            props.onCompleted();

        }).catch((error) => {
            setIsLoading(false);

            openPopup(<ErrorPopup><div>{error.response.data.message}</div></ErrorPopup>);
        });
    }, [isFormValid, projectNameFormControl, externalIdFormControl, props.projectId, openPopup, props.onCompleted, setIsLoading]);

    /****************************
     * CSS & HTML
     *****************************/

    if (isLoading) {
        return (
            <PopupContainer className="edit-project-popup">
                <Loader />
            </PopupContainer>
        )
    }

    return (
        <PopupContainer className="edit-project-popup">
            <FormContainer className="inputs">
                <FormFieldTextInput formControl={projectNameFormControl} label="Project Name" />
                <FormFieldTextInput formControl={externalIdFormControl} label="External ID" />
            </FormContainer>

            <LineSeparator />

            <div className="act-btns">
                <TextButton type="white" text={"Cancel"} onClick={closePopup} />
                <Button text="Edit" type="primary" isDisabled={isButtonDisabled}
                    onClick={handleOnClickEditProject} />
            </div>
        </PopupContainer>
    );
}

